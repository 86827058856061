import React, { useContext } from 'react';
import Web3 from 'web3';
import { SnackbarContext } from '../../../context/customsnackbar-context';
import { SettingsContext } from '../../../context/settings-context';
import { MyThemeContext } from '../../../context/theme-context';
import { Web3Context } from '../../../context/web3-context';

import { Avatar, Box, Badge, Button, CircularProgress,Dialog, DialogContent, DialogContentText, DialogTitle, Slide, TextField, Tooltip, Typography } from '@mui/material';
import { blue,green } from '@mui/material/colors';
import SendIcon from '@mui/icons-material/Send';

import MenuItemAllowanceCoin from './WalletSalesMenuItemAllowanceCoin';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

function WalletSalesMenu({ open, setOpen, scList, coinList, showWallet = false, tokens, newOrModifyOffers, setNewOrModifyOffers, handleSendOffers,waitSendOffers }) {
  const { openSnackbar } = useContext(SnackbarContext);
  const { web3active, web3functions, web3smartContract } = useContext(Web3Context);
  const { settingsMarket, setSettingsMarket, settingsWallet, blockchainsClient } = useContext(SettingsContext);
  const { themeContext } = useContext(MyThemeContext);

  const bc = blockchainsClient['Gnosis'];
  const _web3 = new Web3(bc.rpc);
  const ERC20ABI = web3smartContract.ERC20ABI;

  const newOrModifyOffersToSendCount = newOrModifyOffers.length

  const handleClose = () => {
    setOpen(false);
  };

  const getAllowanceCoin = async (coinAddress) => {
    try {
      if (settingsMarket?.WalletSaleOffers?.useSc) {
        let spender = web3smartContract.yamAddress;
        if (settingsMarket.WalletSaleOffers.useSc.id === 'swapcat') spender = web3smartContract.swapcatAddress;

        const coinContract = new _web3.eth.Contract(ERC20ABI, coinAddress);
        const allowance = Number(await coinContract.methods.allowance(settingsWallet.selectedWallet.toLowerCase(), spender).call());
        const balance = Number(await coinContract.methods.balanceOf(settingsWallet.selectedWallet.toLowerCase()).call());
        const decimals = Number(await coinContract.methods.decimals().call());
        const formattedAllowance = allowance / 10 ** decimals;
        const formattedBalance = balance / 10 ** decimals;

        // console.log('formattedAllowance', formattedAllowance);
        return formattedBalance < formattedAllowance ? formattedBalance : formattedAllowance;
      }
    } catch (error) {
      console.error('getAllowanceCoin Error:', error);
    }
  };

  const handleCreateOffer = async () => {
    // Vérifiez l'autorisation pour le coin utilisé
    const allowance = await getAllowanceCoin(settingsMarket.WalletSaleOffers.useCoin.address.toLowerCase());
    if (!allowance) {
      console.warn('No allowance available.');
      openSnackbar(`Define an allowance for ${settingsMarket.WalletSaleOffers.useCoin.symbol} !`, 'error');
      return false;
    }
    // else openSnackbar(`Allowance for ${settingsMarket.WalletSaleOffers.useCoin.symbol} =  ${allowance}`, 'success');

    let offerCount = newOrModifyOffers.length; // Compteur local pour limiter à 500
    if (offerCount >= 500) {
      web3functions.setProgress(`Your are limited to prepared 500 Offers maximum !`);
      return;
    }

    // Parcourir tous les tokens
    tokens.forEach((token) => {
      if (!token.offersList || token.offersList.length === 0) return; // Skip tokens without offers

      // Parcourir toutes les offres d'un token
      token.offersList.forEach((offer) => {
        if (offerCount >= 500) {
          web3functions.setProgress(`Your are limited to prepared 500 Offers maximum !`);
          return;
        }
        const isMatchingCoin = settingsMarket.WalletSaleOffers.useCoin.address.toLowerCase() === offer.token_to_pay.toLowerCase();
        const isMatchingSc = settingsMarket.WalletSaleOffers.useSc.id === offer.sc;

        if (isMatchingCoin && isMatchingSc) {
          // Calculer les nouvelles valeurs en fonction des paramètres
          const ratioPrice = settingsMarket.WalletSaleOffers.useRealTPrice
            ? 0.97 - 0.05 * (1 - token.rentedUnits / token.totalUnits)
            : 1 + settingsMarket.WalletSaleOffers.usePremium / 100;

          const price = token.tokenPrice * ratioPrice;
          const amount = allowance / price; // Math.round((allowance / price) * 100000) / 100000;
          const newYield = token.netRentYearPerToken / price;

          // Vérifiez si une mise à jour est nécessaire
          if (!(0.999 * offer.token_value < price && price < offer.token_value * 1.001) || !(0.99 * offer.token_amount < amount && amount < offer.token_amount * 1.01)) {
            const editOfferId = offer.editOffer ? offer.editOffer.id : 0;
            const editOffer = {
              sc: settingsMarket.WalletSaleOffers.useSc,
              coin: settingsMarket.WalletSaleOffers.useCoin,
              amount: amount,
              price: price,
              newYield: newYield,
              ratioPrice: ratioPrice,
              tokenToBuy: offer.token_to_buy,
              isEditable: false,
              toSend: true,
              isSended: false,
              isDeleted: false,
              shortName: token.shortName,
            };
            // console.log('create editOffer', offer.id_offer, token.shortName, offer.token_amount, offer.token_value, editOffer);

            // Appel à la méthode handleEditOffers
            token.handleEditOffers(editOfferId, offer.id_offer, token.shortName, editOffer);
            offerCount += 1;
          }
        }
      });
    });
    web3functions.setProgress(null);
  };

  const handleUpdateAmountPrice = async () => {
    // Vérifiez l'autorisation pour le coin utilisé
    const allowance = await getAllowanceCoin(settingsMarket.WalletSaleOffers.useCoin.address.toLowerCase());
    if (!allowance) {
      console.warn('No allowance available.');
      openSnackbar(`Define an allowance for ${settingsMarket.WalletSaleOffers.useCoin.symbol} !`, 'error');
      return false;
    }
    // else openSnackbar(`Allowance for ${settingsMarket.WalletSaleOffers.useCoin.symbol} =  ${allowance}`, 'success');

    let offerCount = newOrModifyOffers.length; // Compteur local pour limiter à 500
    if (offerCount >= 500) {
      web3functions.setProgress(`Your are limited to prepared 500 Offers maximum !`);
      return;
    }

    // Parcourir tous les tokens
    tokens.forEach((token) => {
      if (!token.offersList || token.offersList.length === 0) return; // Skip tokens without offers

      // Parcourir toutes les offres d'un token
      token.offersList.forEach((offer) => {
        if (offerCount >= 500) {
          web3functions.setProgress(`Your are limited to prepared 500 Offers maximum !`);
          return;
        }
        const isMatchingCoin = settingsMarket.WalletSaleOffers.useCoin.address.toLowerCase() === offer.token_to_pay.toLowerCase();
        const isMatchingSc = settingsMarket.WalletSaleOffers.useSc.id === offer.sc;

        if (isMatchingCoin && isMatchingSc) {
          // Calculer les nouvelles valeurs en fonction des paramètres
          const ratioPrice = settingsMarket.WalletSaleOffers.useRealTPrice
            ? 0.97 - 0.05 * (1 - token.rentedUnits / token.totalUnits)
            : 1 + settingsMarket.WalletSaleOffers.usePremium / 100;

          const price = token.tokenPrice * ratioPrice;
          const amount = allowance / price; // Math.round((allowance / price) * 100000) / 100000;
          const newYield = token.netRentYearPerToken / price;

          // Vérifiez si une mise à jour est nécessaire
          if (!(0.999 * offer.token_value < price && price < offer.token_value * 1.001) || !(0.99 * offer.token_amount < amount && amount < offer.token_amount * 1.01)) {
            const editOfferId = offer.editOffer ? offer.editOffer.id : 0;
            const editOffer = {
              sc: settingsMarket.WalletSaleOffers.useSc,
              coin: settingsMarket.WalletSaleOffers.useCoin,
              amount: amount,
              price: price,
              newYield: newYield,
              ratioPrice: ratioPrice,
              tokenToBuy: offer.token_to_buy,
              isEditable: false,
              toSend: true,
              isSended: false,
              isDeleted: false,
              shortName: token.shortName,
            };
            // console.log('create editOffer', offer.id_offer, token.shortName, offer.token_amount, offer.token_value, editOffer);

            // Appel à la méthode handleEditOffers
            token.handleEditOffers(editOfferId, offer.id_offer, token.shortName, editOffer);
            web3functions.setProgress(`Your prepared ${offerCount} Offers maximum !`);
            console.log(`Your prepared ${offerCount} Offers maximum !`);
            offerCount += 1;
          }
        }
      });
    });
    // web3functions.setProgress(null);
  };

  const handleUpdateAmountOnly = async () => {
    // Vérifiez l'autorisation pour le coin utilisé
    const allowance = await getAllowanceCoin(settingsMarket.WalletSaleOffers.useCoin.address.toLowerCase());
    if (!allowance) {
      console.warn('No allowance available.');
      openSnackbar(`Define an allowance for ${settingsMarket.WalletSaleOffers.useCoin.symbol} !`, 'error');
      return false;
    }
    // else openSnackbar(`Allowance for ${settingsMarket.WalletSaleOffers.useCoin.symbol} =  ${allowance}`, 'success');

    let offerCount = newOrModifyOffers.length; // Compteur local pour limiter à 500
    if (offerCount >= 500) {
      web3functions.setProgress(`Your are limited to prepared 500 Offers maximum !`);
      return;
    }

    // Parcourir tous les tokens
    tokens.forEach((token) => {
      if (!token.offersList || token.offersList.length === 0) return; // Skip tokens without offers

      // Parcourir toutes les offres d'un token
      token.offersList.forEach((offer) => {
        if (offerCount >= 500) {
          web3functions.setProgress(`Your are limited to prepared 500 Offers maximum !`);
          return;
        }
        const isMatchingCoin = settingsMarket.WalletSaleOffers.useCoin.address.toLowerCase() === offer.token_to_pay.toLowerCase();
        const isMatchingSc = settingsMarket.WalletSaleOffers.useSc.id === offer.sc;

        if (isMatchingCoin && isMatchingSc) {
          // Calculer les nouvelles valeurs en fonction des paramètres
          const ratioPrice = token.ratioPrice;

          const price = token.tokenPrice;
          const amount = allowance / price; // Math.round((allowance / price) * 100000) / 100000;
          const newYield = token.netRentYearPerToken / price;

          // Vérifiez si une mise à jour est nécessaire
          if (!(0.999 * offer.token_value < price && price < offer.token_value * 1.001) || !(0.99 * offer.token_amount < amount && amount < offer.token_amount * 1.01)) {
            const editOfferId = offer.editOffer ? offer.editOffer.id : 0;
            const editOffer = {
              sc: settingsMarket.WalletSaleOffers.useSc,
              coin: settingsMarket.WalletSaleOffers.useCoin,
              amount: amount,
              price: price,
              newYield: newYield,
              ratioPrice: ratioPrice,
              tokenToBuy: offer.token_to_buy,
              isEditable: false,
              toSend: true,
              isSended: false,
              isDeleted: false,
              shortName: token.shortName,
            };
            // console.log('create editOffer', offer.id_offer, token.shortName, offer.token_amount, offer.token_value, editOffer);

            // Appel à la méthode handleEditOffers
            token.handleEditOffers(editOfferId, offer.id_offer, token.shortName, editOffer);
            web3functions.setProgress(`Your prepared ${offerCount} Offers maximum !`);
            console.log(`Your prepared ${offerCount} Offers maximum !`);
            offerCount += 1;
          }
        }
      });
    });
    // web3functions.setProgress(null);
  };

  const handleDeleteAllOffers = async () => {
    const callback = (result) => {
      if (result) {
        console.log('Transaction sended successfully:', result);
      }
    };
    if (window.confirm(`Are you sure you want to Delete All Offers ?`)) {
      // console.log('settingsMarket', settingsMarket.WalletSaleOffers);
      let offersIds_yam = [];
      let offersIds_swapcat = [];
      tokens.forEach((token) => {
        console.log('token', token.shortName);
        token.offersList.forEach((offer) => {
          if (offer.sc === 'swapcat') {
            console.log('token', token.shortName, ' -> offer', offer.sc, offer.id_offer);
            offersIds_swapcat.push(offer.id_offer);
          }
          if (offer.sc === 'yam') {
            console.log('token', token.shortName, ' -> offer', offer.sc, offer.id_offer);
            offersIds_yam.push(offer.id_offer);
          }
        });
      });
      if (offersIds_swapcat.length > 0) {
        for (const offerId of offersIds_swapcat) {
          await web3functions.deleteSwapCat(offerId, callback);
        }
        // await web3functions.marketSwapCatHolderUpdate();
      }
      if (offersIds_yam.length > 0) {
        await web3functions.deleteYamBatch(offersIds_yam, callback);
        // await web3functions.marketYamHolderUpdate();
      }
    }
  };

  const handleDeleteAllCoinOffers = async () => {
    const callback = (result) => {
      if (result) {
        console.log('Transaction sended successfully:', result);
      }
    };

    // console.log('settingsMarket', settingsMarket.WalletSaleOffers);
    let offersIds_yam = [];
    let offersIds_swapcat = [];
    tokens.forEach((token) => {
      token.offersList.forEach((offer) => {
        if (settingsMarket.WalletSaleOffers.useCoin.address.toLowerCase() === offer.token_to_pay.toLowerCase() && offer.sc === 'swapcat') {
          console.log('token', token.shortName, ' -> offer', offer.sc, offer.id_offer);
          offersIds_swapcat.push(offer.id_offer);
        }
        if (settingsMarket.WalletSaleOffers.useCoin.address.toLowerCase() === offer.token_to_pay.toLowerCase() && offer.sc === 'yam') {
          console.log('token', token.shortName, ' -> offer', offer.sc, offer.id_offer);
          offersIds_yam.push(offer.id_offer);
        }
      });
    });
    if (offersIds_swapcat.length > 0) {
      for (const offerId of offersIds_swapcat) {
        await web3functions.deleteSwapCat(offerId, callback);
      }
      // await web3functions.marketSwapCatHolderUpdate();
    }
    if (offersIds_yam.length > 0) {
      await web3functions.deleteYamBatch(offersIds_yam, callback);
      // await web3functions.marketYamHolderUpdate();
    }
  };

  const handleDeleteAllSmartcontractOffers = async () => {
    const callback = (result) => {
      if (result) {
        console.log('Transaction sended successfully:', result);
      }
    };

    // console.log('settingsMarket', settingsMarket.WalletSaleOffers);
    let offersIds_yam = [];
    let offersIds_swapcat = [];
    tokens.forEach((token) => {
      token.offersList.forEach((offer) => {
        if (settingsMarket.WalletSaleOffers.useSc.id === offer.sc && offer.sc === 'swapcat') {
          console.log('token', token.shortName, ' -> offer', offer.sc, offer.id_offer);
          offersIds_swapcat.push(offer.id_offer);
        }
        if (settingsMarket.WalletSaleOffers.useSc.id === offer.sc && offer.sc === 'yam') {
          console.log('token', token.shortName, ' -> offer', offer.sc, offer.id_offer);
          offersIds_yam.push(offer.id_offer);
        }
      });
    });
    if (offersIds_swapcat.length > 0) {
      for (const offerId of offersIds_swapcat) {
        await web3functions.deleteSwapCat(offerId, callback);
      }
      // await web3functions.marketSwapCatHolderUpdate();
    }
    if (offersIds_yam.length > 0) {
      await web3functions.deleteYamBatch(offersIds_yam, callback);
      // await web3functions.marketYamHolderUpdate();
    }
  };

  const handleDeleteAllLowAmountOffers = async () => {
    const callback = (result) => {
      if (result) {
        console.log('Transaction sended successfully:', result);
      }
    };

    // console.log('settingsMarket', settingsMarket.WalletSaleOffers);
    let offersIds_yam = [];
    let offersIds_swapcat = [];

    tokens.forEach((token) => {
      token.offersList.forEach((offer) => {
        if (offer.token_amount < 0.01 && offer.sc === 'swapcat') {
          console.log('token', token.shortName, ' -> offer', offer.sc, offer.id_offer);
          offersIds_swapcat.push(offer.id_offer);
        }
        if (offer.token_amount < 0.01 && offer.sc === 'yam') {
          console.log('token', token.shortName, ' -> offer', offer.sc, offer.id_offer);
          offersIds_yam.push(offer.id_offer);
        }
      });
    });
    if (offersIds_swapcat.length > 0) {
      for (const offerId of offersIds_swapcat) {
        await web3functions.deleteSwapCat(offerId, callback);
      }
      // await web3functions.marketSwapCatHolderUpdate();
    }
    if (offersIds_yam.length > 0) {
      await web3functions.deleteYamBatch(offersIds_yam, callback);
      // await web3functions.marketYamHolderUpdate();
    }
  };

  // console.log("tokensS", tokens)
  // console.log("newOrModifyOffers", newOrModifyOffers)

  const SIZE_ICON = 20;

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      sx={{
        m: 0,
        width: 1,
        '& .MuiDialog-paper': {
          width: 800,
          maxWidth: 'none',
          border: 5,
          color: blue[600],
          borderRadius: 0,
        },
      }}>
      <DialogTitle sx={{ mx: 1, mt: 1, p: 0, border: 0 }}>
        <Box sx={{ border: 2, color: blue[600], display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start' }}>
          <Typography variant='h5' sx={{ p: 1, color: blue[600] }}>
            {'Advanced settings'}
          </Typography>
          {newOrModifyOffersToSendCount > 0 && (
            <Tooltip title='Send Grouped offers, only Yam offers' placement='top'>
              <Badge
                sx={{ ml: 'auto', mr: 5, mt: 0, fontSize: 20 }} //cursor: "not-allowed"
                badgeContent={newOrModifyOffersToSendCount}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                componentsProps={{
                  badge: {
                    sx: {
                      transform: 'translate(30px, 0)', // Décalage de 10px vers la droite
                    },
                  },
                }}
                color='primary'>
                {waitSendOffers ? (
                  <CircularProgress sx={{ mx: 1, color: blue[700] }} size='1rem' />
                ) : (
                  <SendIcon
                    sx={{
                      mx: 1,
                      fontSize: 22,
                      color: blue[700],
                      cursor: 'pointer',
                    }}
                    onClick={handleSendOffers}
                  />
                )}
              </Badge>
            </Tooltip>
          )}
        </Box>
      </DialogTitle>
      <DialogContent sx={{ m: 0, p: 0, border: 0 }}>
        <DialogContentText id='alert-dialog-slide-description' sx={{ m: 1, border: 0, color: blue[500] }}>
          <Box sx={{ m: 0, border: 0, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
            <Box sx={{ m: 0, border: 0, display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between', width: 1 }}>
              {/* Set coins Allowance */}
              <Box sx={{ py: 0.5, border: 0, flexGrow: 1 }}>
                <Box
                  sx={{
                    m: 0,
                    border: 0,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                  }}>
                  <Typography
                    variant='body2'
                    sx={{
                      ml: 1,
                      color: 'inherit',
                      fontWeight: 'bold',
                      textDecoration: 'underline',
                    }}>
                    {'Set coins Allowance:'}
                  </Typography>
                  <Button sx={{ ml: 1, px: 0, minWidth: 35 }} variant='outlined'>
                    {settingsMarket.WalletSaleOffers.useCoin ? (
                      <Avatar
                        alt='coin'
                        src={settingsMarket.WalletSaleOffers.useCoin.imgToken}
                        sx={{
                          m: 0,
                          p: 0,
                          width: SIZE_ICON,
                          height: SIZE_ICON,
                          borderRadius: 0,
                        }}
                      />
                    ) : (
                      <Avatar
                        alt='coin'
                        sx={{
                          m: 0,
                          p: 0,
                          width: SIZE_ICON,
                          height: SIZE_ICON,
                          bgcolor: 'error.main',
                        }}>
                        ?
                      </Avatar>
                    )}
                  </Button>
                </Box>

                {coinList.map((object, index) => (
                  <MenuItemAllowanceCoin key={index} coin={object} handleCloseMenu={() => null} showWallet={showWallet} />
                ))}
              </Box>

              {/* Set smartContract & Premium */}
              <Box sx={{ ml: 5, py: 0.5, border: 0, flexGrow: 1 }}>
                <Box sx={{ p: 0, border: 0, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
                  <Typography variant='body2' sx={{ ml: 0, color: 'inherit', fontWeight: 'bold', textDecoration: 'underline' }}>
                    {'Set default SmartContract:'}
                  </Typography>
                  <Button sx={{ ml: 1, px: 0, minWidth: 35 }} variant='outlined'>
                    {settingsMarket.WalletSaleOffers.useSc ? (
                      <Avatar alt='sc' src={settingsMarket.WalletSaleOffers.useSc.img} sx={{ m: 0, p: 0, width: SIZE_ICON, height: SIZE_ICON, borderRadius: 0 }} />
                    ) : (
                      <Avatar alt='sc' sx={{ m: 0, p: 0, width: SIZE_ICON, height: SIZE_ICON, bgcolor: 'error.main' }}>
                        ?
                      </Avatar>
                    )}
                  </Button>
                </Box>

                {scList.map((object, index) => (
                  <Box
                    sx={{
                      ml: 2,
                      border: 0,
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'start',
                      justifyContent: 'start',
                      cursor: 'pointer',
                    }}
                    onClick={(e) => {
                      // console.log("object", object);
                      let settings = JSON.parse(JSON.stringify(settingsMarket));
                      settings.WalletSaleOffers.useSc = object;
                      setSettingsMarket(settings);
                    }}>
                    <>
                      <Avatar
                        key={object.name}
                        alt={object.name}
                        src={object.img}
                        sx={{
                          ml: 0,
                          width: 20,
                          height: 20,
                          borderRadius: 0,
                        }}
                      />

                      <Box sx={{ ml: 1 }}>
                        <Typography variant='body2' sx={{ m: 0, color: 'inherit' }}>
                          {object.label}
                        </Typography>
                      </Box>
                    </>
                  </Box>
                ))}

                <Box sx={{ mt: 1.5, border: 0, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
                  <Typography variant='body2' sx={{ ml: 0, color: 'inherit', fontWeight: 'bold', textDecoration: 'underline' }}>
                    {'Set purchase Premium:'}
                  </Typography>
                  <Button sx={{ ml: 1, px: 0, minWidth: 35 }} variant='outlined'>
                    {settingsMarket.WalletSaleOffers.useRealTPrice ? (
                      <>
                        <Avatar
                          alt='realtPrice'
                          src={'./images/yam.png'}
                          sx={{ mr: 0.5, p: 0, width: SIZE_ICON, height: SIZE_ICON, borderRadius: 0, cursor: 'pointer' }}
                          onClick={() => {
                            setSettingsMarket((prevSettings) => ({
                              ...prevSettings,
                              WalletSaleOffers: {
                                ...prevSettings.WalletSaleOffers,
                                useRealTPrice: !prevSettings.WalletSaleOffers.useRealTPrice,
                              },
                            }));
                          }}
                        />
                      </>
                    ) : (
                      <>
                        <Avatar
                          alt='realtPrice'
                          src={'./images/premium.png'}
                          sx={{ mr: 0.5, p: 0, width: SIZE_ICON, height: SIZE_ICON, borderRadius: 4, cursor: 'pointer' }}
                          onClick={() => {
                            setSettingsMarket((prevSettings) => ({
                              ...prevSettings,
                              WalletSaleOffers: {
                                ...prevSettings.WalletSaleOffers,
                                useRealTPrice: !prevSettings.WalletSaleOffers.useRealTPrice,
                              },
                            }));
                          }}
                        />
                      </>
                    )}
                  </Button>
                </Box>

                <Box sx={{ ml: 2, border: 0, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
                  <Avatar
                    alt='realtPrice'
                    src={'./images/yam.png'}
                    sx={{ mr: 0.5, p: 0, width: SIZE_ICON, height: SIZE_ICON, borderRadius: 0, cursor: 'pointer' }}
                    onClick={() => {
                      setSettingsMarket((prevSettings) => ({
                        ...prevSettings,
                        WalletSaleOffers: {
                          ...prevSettings.WalletSaleOffers,
                          useRealTPrice: true,
                        },
                      }));
                    }}
                  />
                  <Typography
                    variant='body2'
                    sx={{ cursor: 'pointer' }}
                    onClick={() => {
                      setSettingsMarket((prevSettings) => ({
                        ...prevSettings,
                        WalletSaleOffers: {
                          ...prevSettings.WalletSaleOffers,
                          useRealTPrice: !prevSettings.WalletSaleOffers.useRealTPrice,
                        },
                      }));
                    }}>
                    RealT Price
                  </Typography>
                </Box>

                <Box sx={{ ml: 2, border: 0, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
                  <Avatar
                    alt='realtPrice'
                    src={'./images/premium.png'}
                    sx={{ mr: 0.5, p: 0, width: SIZE_ICON, height: SIZE_ICON, borderRadius: 4, cursor: 'pointer' }}
                    onClick={() => {
                      setSettingsMarket((prevSettings) => ({
                        ...prevSettings,
                        WalletSaleOffers: {
                          ...prevSettings.WalletSaleOffers,
                          useRealTPrice: false,
                        },
                      }));
                    }}
                  />
                  <TextField
                    sx={{ m: 0, p: 0 }}
                    // label='Pourcentage personnalisé'
                    variant='standard'
                    size='small'
                    type='number'
                    value={settingsMarket.WalletSaleOffers.usePremium || 0}
                    // onChange={handlePercentageChange}
                    onChange={(e) => {
                      let settings = JSON.parse(JSON.stringify(settingsMarket));
                      console.log('usePremium', e.target.value);
                      settings.WalletSaleOffers.usePremium = e.target.value;
                      setSettingsMarket(settings);
                    }}
                    inputProps={{
                      min: -100,
                      max: 100,
                      style: {
                        textAlign: 'center',
                      },
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              mt: 1,
              border: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
            }}></Box>

          {web3active ? (
            <Box sx={{ ml: 0, border: 0, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
              <Box sx={{ my: 0, border: 0, display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between', width: 1 }}>
                {/* Colonne 1 */}
                <Box sx={{ py: 0.5, border: 0, flexGrow: 1 }}>
                  {/* Create all offers for the selected coins */}
                  <Box sx={{ py: 0.5, border: 0, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
                    <Button sx={{ mr: 1, px: 0, minWidth: 20, minHeight: 20 }} variant='contained' onClick={handleCreateOffer}></Button>
                    <Typography variant='body2' sx={{ p: 0, color: blue[600] }}>
                      {'Create all offers for the selected realTokens'}
                    </Typography>
                  </Box>

                  {/* Update Amount */}
                  <Box sx={{ py: 0.5, border: 0, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
                    <Button sx={{ mr: 1, px: 0, minWidth: 20, minHeight: 20 }} variant='contained' onClick={handleUpdateAmountPrice}></Button>
                    <Typography variant='body2' sx={{ p: 0, color: blue[600] }}>
                      {'Update Quantity and Price'}
                    </Typography>
                  </Box>

                  {/* not define .... */}
                  <Box sx={{ py: 0.5, border: 0, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
                    <Button sx={{ mr: 1, px: 0, minWidth: 20, minHeight: 20 }} variant='contained' onClick={handleUpdateAmountOnly}></Button>
                    <Typography variant='body2' sx={{ p: 0, color: blue[600] }}>
                      {'Update Quantity only'}
                    </Typography>
                  </Box>

                  {/* Delete All prepared Offers */}
                  <Box sx={{ py: 0.5, border: 0, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
                    <Button
                      sx={{ mr: 1, px: 0, minWidth: 20, minHeight: 20 }}
                      variant='contained'
                      color='secondary'
                      onClick={() => {
                        web3functions.setProgress(null);
                        setNewOrModifyOffers([]);
                      }}></Button>
                    <Typography variant='body2' sx={{ p: 0, color: blue[600] }}>
                      {'Delete All prepared Offers'}
                    </Typography>
                  </Box>

                  {/* Fin Colonne 1 */}
                </Box>

                {/* Colonne 2 */}
                <Box sx={{ py: 0.5, border: 0, flexGrow: 1 }}>
                  {/* Delete All Offers */}
                  <Box sx={{ py: 0.5, border: 0, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
                    <Button sx={{ mr: 1, px: 0, minWidth: 20, minHeight: 20 }} variant='contained' color='error' onClick={handleDeleteAllOffers}></Button>
                    <Typography variant='body2' sx={{ p: 0, color: blue[600] }}>
                      {'Delete All Offers '}
                    </Typography>
                  </Box>

                  {/* Delete All Offers with an Amount <0,01 */}
                  <Box sx={{ py: 0.5, border: 0, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
                    <Button sx={{ mr: 1, px: 0, minWidth: 20, minHeight: 20 }} variant='contained' color='warning' onClick={handleDeleteAllLowAmountOffers}></Button>
                    <Typography variant='body2' sx={{ p: 0, color: blue[600] }}>
                      {'Delete All Offers with an Quantity <0,01'}
                    </Typography>
                  </Box>

                  {/* Delete All Offers with the selected coin */}
                  <Box sx={{ py: 0.5, border: 0, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
                    <Button sx={{ mr: 1, px: 0, minWidth: 20, minHeight: 20 }} variant='contained' color='warning' onClick={handleDeleteAllCoinOffers}></Button>
                    <Typography variant='body2' sx={{ p: 0, color: blue[600] }}>
                      {'Delete All Offers with the selected coin'}
                    </Typography>
                  </Box>

                  {/* Delete All Offers with the selected smartcontract */}
                  <Box sx={{ py: 0.5, border: 0, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
                    <Button sx={{ mr: 1, px: 0, minWidth: 20, minHeight: 20 }} variant='contained' color='warning' onClick={handleDeleteAllSmartcontractOffers}></Button>
                    <Typography variant='body2' sx={{ p: 0, color: blue[600] }}>
                      {'Delete All Offers with the selected smartcontract'}
                    </Typography>
                  </Box>

                  {/*  */}

                  {/* Fin Colonne 2 */}
                </Box>
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                ml: 0,
                py: 3,
                border: 0,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <Button variant='contained' onClick={() => web3functions.connect()}>
                Connect Wallet
              </Button>
            </Box>
          )}

          <Box
            sx={{
              ml: 0,
              border: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
            }}></Box>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}

export default WalletSalesMenu;
