import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import Web3 from 'web3';
// src/context/web3-context.jsx

import { SnackbarContext } from './customsnackbar-context';
import { SettingsContext } from './settings-context';

import { Alert, Snackbar } from '@mui/material';

export const Web3Context = createContext();

const intToHex = (int) => {
  return '0x' + int.toString(16).padStart(2, '0');
};
const hexToInt = (hex) => {
  return parseInt(hex, 16);
};

const WORKER_PITSBI_URL = 'https://worker.pitsbi.io/api/';

const ProgressPopup = ({ message }) => {
  if (!message) return null;

  return (
    <Snackbar
      open={Boolean(message)}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      // TransitionComponent={(props) => <Slide {...props} direction='right' />}
      autoHideDuration={10000} // Popup disparaîtra automatiquement après 3 secondes
    >
      <Alert severity='info' sx={{ backgroundColor: 'rgba(0, 0, 0, 0.8)', color: 'white' }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export const Web3Provider = ({ children }) => {
  const { openSnackbar } = useContext(SnackbarContext);
  const { blockchainsClient, holderWallets, setHolderWallets, settingsWallet, setSettingsWallet } = useContext(SettingsContext);
  const { realTokens, balanceWallets, user, setUser } = useContext(SettingsContext);
  const [web3, setWeb3] = useState(null);
  const [web3active, setWeb3Active] = useState(false);
  const [web3account, setWeb3account] = useState(null);
  const [web3accountName, setWeb3accountName] = useState(null);

  const [progress, setProgress] = useState(null);
  const [tokens, setTokens] = useState(null);

  const userTokensBalance = useMemo(() => {
    let tokensBalance = [
      { label: 'Ethereum', chain: 'eth', count: 0, rent: 0, capital: 0, yield: 0 },
      { label: 'Gnosis', chain: 'xdai', count: 0, rent: 0, capital: 0, yield: 0 },
      { label: 'RMM', chain: 'rmm', count: 0, rent: 0, capital: 0, yield: 0 },
      { label: 'RMM v3', chain: 'rmmv3', count: 0, rent: 0, capital: 0, yield: 0 },
      { label: 'Levinswap', chain: 'pool', count: 0, rent: 0, capital: 0, yield: 0 },
      { label: 'Total', chain: 'total', count: 0, rent: 0, capital: 0, yield: 0 },
    ];

    if (!tokens || !balanceWallets) return tokensBalance;

    if (!user?.jwtoken) {
      console.log('user setting without jwtoken', user);
      const walletConnected = settingsWallet.selectedWallet;
      try {
        let params = { main_wallet: walletConnected };

        fetch(`${WORKER_PITSBI_URL}connect`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(params), // Paramètre "caché" dans le corps
        }).then((reponse) => {
          if (reponse.ok) {
            reponse.json().then((body) => {
              console.log('user', body);
              setUser(body);

              let _holderWallets = holderWallets ? JSON.parse(JSON.stringify(holderWallets)) : [];
              body.wallets.forEach((wallet, index) => {
                if (!_holderWallets?.some((hWallet) => hWallet.address.toLowerCase() === wallet.toLowerCase())) {
                  _holderWallets.push({ address: wallet.toLowerCase(), checked: true, name: `RealT n°${index + 1}` });
                }
              });
              setHolderWallets(_holderWallets);
              return tokensBalance;
            });
          } else {
            reponse.json().then((body) => {
              console.log('bad reponse', body);
            });
          }
        });
      } catch (error) {
        // TypeError: Failed to fetch
        console.log('connect', '- fetch error :', error);
      }
      return tokensBalance;
    }

    if (tokens && balanceWallets && user?.wallets)
      tokensBalance.forEach((tokenBalance) => {
        // console.log('user', user.wallets);
        user.wallets.forEach((holderWallet) => {
          for (const wallet of Object.keys(balanceWallets)) {
            if (holderWallet === wallet) {
              for (const chain of Object.keys(balanceWallets[wallet].tokens)) {
                if (tokenBalance.chain === 'eth' || tokenBalance.chain === 'total') {
                  if (chain === 'eth') {
                    const tokensList = balanceWallets[wallet].tokens[chain].tokens;
                    if (tokensList)
                      tokensList.forEach((token) => {
                        const realToken = tokens.filter((t) => t.ethereumContract === token.token.address)[0];
                        tokenBalance.count += parseFloat(token.amount);
                        if (realToken) {
                          tokenBalance.rent += parseFloat(token.amount * realToken.netRentYearPerToken);
                          tokenBalance.capital += parseFloat(token.amount * realToken.tokenPrice);
                          if (tokenBalance.capital > 0) tokenBalance.yield = parseFloat(tokenBalance.rent / tokenBalance.capital);
                        }
                      });
                  }
                }

                if (tokenBalance.chain === 'xdai' || tokenBalance.chain === 'total') {
                  if (chain === 'xdai') {
                    const tokensList = balanceWallets[wallet].tokens[chain].tokens;
                    if (tokensList)
                      tokensList.forEach((token) => {
                        // if (token.token.id === "0x0675e8f4a52ea6c845cb6427af03616a2af42170") console.log("xdai", token.amount, token.token.id);
                        if (token.token.id !== '0x0675e8f4a52ea6c845cb6427af03616a2af42170') {
                          const realToken = tokens.filter((t) => t.gnosisContract === token.token.address)[0];
                          tokenBalance.count += parseFloat(token.amount);
                          if (realToken) {
                            tokenBalance.rent += parseFloat(token.amount * realToken.netRentYearPerToken);
                            tokenBalance.capital += parseFloat(token.amount * realToken.tokenPrice);
                            if (tokenBalance.capital > 0) tokenBalance.yield = parseFloat(tokenBalance.rent / tokenBalance.capital);
                          }
                        }
                      });
                  }
                }

                if (tokenBalance.chain === 'rmm' || tokenBalance.chain === 'total') {
                  if (chain === 'rmm') {
                    const tokensList = balanceWallets[wallet].tokens[chain].tokens;
                    if (tokensList)
                      tokensList.forEach((token) => {
                        const rmmBalance = parseInt(token.currentATokenBalance) / 10 ** token.reserve.decimals;
                        if (token.reserve.underlyingAsset !== '0xe91d153e0b41518a2ce8dd3d7944fa863463a97d') {
                          const realToken = tokens.filter((t) => t.gnosisContract === token.reserve.underlyingAsset)[0];
                          tokenBalance.count += parseFloat(rmmBalance);
                          if (realToken) {
                            tokenBalance.rent += parseFloat(rmmBalance * realToken.netRentYearPerToken);
                            tokenBalance.capital += parseFloat(rmmBalance * realToken.tokenPrice);
                            if (tokenBalance.capital > 0) tokenBalance.yield = parseFloat(tokenBalance.rent / tokenBalance.capital);
                          }
                        }
                      });
                  }
                }

                if (tokenBalance.chain === 'rmmv3' || tokenBalance.chain === 'total') {
                  if (chain === 'rmmv3') {
                    const tokensList = balanceWallets[wallet].tokens[chain].tokens;
                    if (tokensList)
                      tokensList.forEach((token) => {
                        const realToken = tokens.filter((t) => t.gnosisContract === token.token.id.toLowerCase())[0];
                        const rmmBalance = parseInt(token.amount) / 10 ** token.token.decimals;
                        tokenBalance.count += parseFloat(rmmBalance);
                        if (realToken) {
                          tokenBalance.rent += parseFloat(rmmBalance * realToken.netRentYearPerToken);
                          tokenBalance.capital += parseFloat(rmmBalance * realToken.tokenPrice);
                          if (tokenBalance.capital > 0) tokenBalance.yield = parseFloat(tokenBalance.rent / tokenBalance.capital);
                        }
                      });
                  }
                }

                if (tokenBalance.chain === 'pool' || tokenBalance.chain === 'total') {
                  if (chain === 'pool') {
                    const tokensList = balanceWallets[wallet].tokens[chain].tokens;
                    if (tokensList)
                      tokensList.forEach((token) => {
                        // console.log("chain", tokenBalance.chain, "walletBalance:", walletBalance.wallet, "token", token);
                        const liquidityTokenBalance = parseFloat(token.liquidityTokenBalance);
                        const totalSupply = parseFloat(token.pair.totalSupply);
                        if (tokens.filter((t) => t.gnosisContract === token.pair.token0.id)[0]) {
                          const realToken = tokens.filter((t) => t.gnosisContract === token.pair.token0.id)[0];
                          if (realToken)
                            if (realToken.pool.coinId) {
                              const poolBalance =
                                token.pair.token0.liquidity > 1000
                                  ? (liquidityTokenBalance * token.pair.token0.liquidity) / 10 ** 18 / totalSupply
                                  : (liquidityTokenBalance * token.pair.token0.liquidity) / totalSupply;
                              tokenBalance.count += parseFloat(poolBalance);
                              tokenBalance.rent += parseFloat(poolBalance * realToken.netRentYearPerToken);
                              tokenBalance.capital += parseFloat(poolBalance * realToken.tokenPrice);
                              if (tokenBalance.capital > 0) tokenBalance.yield = parseFloat(tokenBalance.rent / tokenBalance.capital);
                            }
                        }
                        if (tokens.filter((t) => t.gnosisContract === token.pair.token1.id)[0]) {
                          const realToken = tokens.filter((t) => t.gnosisContract === token.pair.token1.id)[0];
                          if (realToken)
                            if (realToken.pool.coinId) {
                              const poolBalance =
                                token.pair.token1.liquidity > 1000
                                  ? (liquidityTokenBalance * token.pair.token1.liquidity) / 10 ** 18 / totalSupply
                                  : (liquidityTokenBalance * token.pair.token1.liquidity) / totalSupply;
                              tokenBalance.count += parseFloat(poolBalance);
                              tokenBalance.rent += parseFloat(poolBalance * realToken.netRentYearPerToken);
                              tokenBalance.capital += parseFloat(poolBalance * realToken.tokenPrice);
                              if (tokenBalance.capital > 0) tokenBalance.yield = parseFloat(tokenBalance.rent / tokenBalance.capital);
                            }
                        }
                      });
                  }
                }
              }
            }
          }
        });
      });

    if (!user?.jwtoken || !user.wallets) {
      console.log('user setting not initialised', !user?.jwtoken, !tokens, !balanceWallets);
      const walletConnected = settingsWallet.selectedWallet;
      try {
        let params = { main_wallet: walletConnected };
        const userBalance = tokensBalance.find((obj) => obj.label === 'Total');
        if (userBalance) params = { ...params, userBalance };

        fetch(`${WORKER_PITSBI_URL}connect`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(params), // Paramètre "caché" dans le corps
        }).then((reponse) => {
          if (reponse.ok) {
            reponse.json().then((body) => {
              console.log('user', body);
              setUser(body);

              let _holderWallets = holderWallets ? JSON.parse(JSON.stringify(holderWallets)) : [];
              body.wallets.forEach((wallet, index) => {
                if (!_holderWallets?.some((hWallet) => hWallet.address.toLowerCase() === wallet.toLowerCase())) {
                  _holderWallets.push({ address: wallet.toLowerCase(), checked: true, name: `RealT n°${index + 1}` });
                }
              });
              setHolderWallets(_holderWallets);
            });
          } else {
            reponse.json().then((body) => {
              console.log('bad reponse', body);
            });
          }
        });
      } catch (error) {
        // TypeError: Failed to fetch
        console.log('connect', '- fetch error :', error);
      }
      return tokensBalance;
    }

    // console.log('tokensBalance');
    // console.table(tokensBalance);

    return tokensBalance;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokens, user, balanceWallets, holderWallets, settingsWallet.selectedWallet]);

  useEffect(() => {
    if (realTokens) {
      let cptTokens = [
        ...realTokens.list.filter((data) => !data.shortName.startsWith('OLD') && data.totalTokens > 0).filter((data) => !data.canal.includes(['exit_complete'])),
      ];

      // console.log('set tokens',cptTokens);
      setTokens(cptTokens);
    }
  }, [realTokens]);

  useEffect(() => {
    if (progress) {
      const timer = setTimeout(
        () => {
          setProgress(null);
        },
        1 * 15 * 1000
      ); // 15 secondes

      return () => clearTimeout(timer);
    }
  }, [progress]);

  useEffect(() => {
    const intervalId = setInterval(
      () => {
        console.log('update_user_connexion', user);

        if (!user?.jwtoken) {
          console.log('user setting not initialised', user);
          // console.table(tokensBalance);
          // return tokensBalance;
        }
        if (tokens && balanceWallets) {
          // const walletConnected = settingsWallet.selectedWallet;
          try {
            let params = { jwtoken: user.jwtoken };
            const userBalance = userTokensBalance.find((obj) => obj.label === 'Total');
            if (userBalance) params = { ...params, userBalance };
            console.log('useEffect1 params', params);


            fetch(`${WORKER_PITSBI_URL}connect`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(params), // Paramètre "caché" dans le corps
            }).then((reponse) => {
              if (reponse.ok) {
                reponse.json().then((body) => {
                  console.log('user', body);
                  setUser(body);

                  let _holderWallets = holderWallets ? JSON.parse(JSON.stringify(holderWallets)) : [];
                  body.wallets.forEach((wallet, index) => {
                    if (!_holderWallets?.some((hWallet) => hWallet.address.toLowerCase() === wallet.toLowerCase())) {
                      _holderWallets.push({ address: wallet.toLowerCase(), checked: true, name: `RealT n°${index + 1}` });
                    }
                  });
                  setHolderWallets(_holderWallets);
                });
              } else {
                reponse.json().then((body) => {
                  console.log('bad reponse', body);
                });
              }
            });
          } catch (error) {
            // TypeError: Failed to fetch
            console.log('connect', '- fetch error :', error);
          }
        }
      },
      5 * 60 * 1000
    );

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokens, user,balanceWallets, holderWallets]);

  const isRealToken = (address) => {
    if (address.toLowerCase() === '0x0675e8F4A52eA6c845CB6427Af03616a2af42170'.toLowerCase()) return 9;
    const isXdai = tokens.find((token) => token.gnosisContract.toLowerCase() === address.toLowerCase());
    if (isXdai) console.log(address, 'isRealToken', isXdai);
    return isXdai ? 18 : false;
  };

  const isCoinBlockChain = (address) => {
    const gnosisArray = Object.values(blockchainsClient['Gnosis'].coinList); // Transforme le dictionnaire en tableau
    const isCoin = gnosisArray.find((coin) => coin.address.toLowerCase() === address.toLowerCase());
    console.log('isCoin', isCoin);
    if (isCoin) console.log(address, 'isCoin', isCoin.decimal);
    return isCoin ? isCoin.decimal : false;
  };

  let isConnecting = false;

  const yamAddress = '0xC759AA7f9dd9720A1502c104DaE4F9852bb17C14'.toLowerCase();
  const swapcatAddress = '0xB18713Ac02Fc2090c0447e539524a5c76f327a3b'.toLowerCase();
  const yamABI = [
    { inputs: [], stateMutability: 'nonpayable', type: 'constructor' },
    {
      anonymous: false,
      inputs: [
        { indexed: false, internalType: 'address', name: 'previousAdmin', type: 'address' },
        { indexed: false, internalType: 'address', name: 'newAdmin', type: 'address' },
      ],
      name: 'AdminChanged',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [{ indexed: true, internalType: 'address', name: 'beacon', type: 'address' }],
      name: 'BeaconUpgraded',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, internalType: 'uint256', name: 'oldFee', type: 'uint256' },
        { indexed: true, internalType: 'uint256', name: 'newFee', type: 'uint256' },
      ],
      name: 'FeeChanged',
      type: 'event',
    },
    { anonymous: false, inputs: [{ indexed: false, internalType: 'uint8', name: 'version', type: 'uint8' }], name: 'Initialized', type: 'event' },
    {
      anonymous: false,
      inputs: [
        { indexed: true, internalType: 'uint256', name: 'offerId', type: 'uint256' },
        { indexed: true, internalType: 'address', name: 'seller', type: 'address' },
        { indexed: true, internalType: 'address', name: 'buyer', type: 'address' },
        { indexed: false, internalType: 'address', name: 'offerToken', type: 'address' },
        { indexed: false, internalType: 'address', name: 'buyerToken', type: 'address' },
        { indexed: false, internalType: 'uint256', name: 'price', type: 'uint256' },
        { indexed: false, internalType: 'uint256', name: 'amount', type: 'uint256' },
      ],
      name: 'OfferAccepted',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, internalType: 'address', name: 'offerToken', type: 'address' },
        { indexed: true, internalType: 'address', name: 'buyerToken', type: 'address' },
        { indexed: false, internalType: 'address', name: 'seller', type: 'address' },
        { indexed: false, internalType: 'address', name: 'buyer', type: 'address' },
        { indexed: true, internalType: 'uint256', name: 'offerId', type: 'uint256' },
        { indexed: false, internalType: 'uint256', name: 'price', type: 'uint256' },
        { indexed: false, internalType: 'uint256', name: 'amount', type: 'uint256' },
      ],
      name: 'OfferCreated',
      type: 'event',
    },
    { anonymous: false, inputs: [{ indexed: true, internalType: 'uint256', name: 'offerId', type: 'uint256' }], name: 'OfferDeleted', type: 'event' },
    {
      anonymous: false,
      inputs: [
        { indexed: true, internalType: 'uint256', name: 'offerId', type: 'uint256' },
        { indexed: false, internalType: 'uint256', name: 'oldPrice', type: 'uint256' },
        { indexed: true, internalType: 'uint256', name: 'newPrice', type: 'uint256' },
        { indexed: false, internalType: 'uint256', name: 'oldAmount', type: 'uint256' },
        { indexed: true, internalType: 'uint256', name: 'newAmount', type: 'uint256' },
      ],
      name: 'OfferUpdated',
      type: 'event',
    },
    { anonymous: false, inputs: [{ indexed: false, internalType: 'address', name: 'account', type: 'address' }], name: 'Paused', type: 'event' },
    {
      anonymous: false,
      inputs: [
        { indexed: true, internalType: 'bytes32', name: 'role', type: 'bytes32' },
        { indexed: true, internalType: 'bytes32', name: 'previousAdminRole', type: 'bytes32' },
        { indexed: true, internalType: 'bytes32', name: 'newAdminRole', type: 'bytes32' },
      ],
      name: 'RoleAdminChanged',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, internalType: 'bytes32', name: 'role', type: 'bytes32' },
        { indexed: true, internalType: 'address', name: 'account', type: 'address' },
        { indexed: true, internalType: 'address', name: 'sender', type: 'address' },
      ],
      name: 'RoleGranted',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, internalType: 'bytes32', name: 'role', type: 'bytes32' },
        { indexed: true, internalType: 'address', name: 'account', type: 'address' },
        { indexed: true, internalType: 'address', name: 'sender', type: 'address' },
      ],
      name: 'RoleRevoked',
      type: 'event',
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, internalType: 'address[]', name: 'tokens', type: 'address[]' },
        { indexed: true, internalType: 'enum IRealTokenYamUpgradeableV3.TokenType[]', name: 'types', type: 'uint8[]' },
      ],
      name: 'TokenWhitelistWithTypeToggled',
      type: 'event',
    },
    { anonymous: false, inputs: [{ indexed: false, internalType: 'address', name: 'account', type: 'address' }], name: 'Unpaused', type: 'event' },
    {
      anonymous: false,
      inputs: [{ indexed: true, internalType: 'address', name: 'implementation', type: 'address' }],
      name: 'Upgraded',
      type: 'event',
    },
    {
      inputs: [],
      name: 'DEFAULT_ADMIN_ROLE',
      outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'MODERATOR_ROLE',
      outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'UPGRADER_ROLE',
      outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'uint256', name: 'offerId', type: 'uint256' },
        { internalType: 'uint256', name: 'price', type: 'uint256' },
        { internalType: 'uint256', name: 'amount', type: 'uint256' },
      ],
      name: 'buy',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'uint256[]', name: '_offerIds', type: 'uint256[]' },
        { internalType: 'uint256[]', name: '_prices', type: 'uint256[]' },
        { internalType: 'uint256[]', name: '_amounts', type: 'uint256[]' },
      ],
      name: 'buyOfferBatch',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'uint256', name: 'offerId', type: 'uint256' },
        { internalType: 'uint256', name: 'price', type: 'uint256' },
        { internalType: 'uint256', name: 'amount', type: 'uint256' },
        { internalType: 'uint256', name: 'deadline', type: 'uint256' },
        { internalType: 'uint8', name: 'v', type: 'uint8' },
        { internalType: 'bytes32', name: 'r', type: 'bytes32' },
        { internalType: 'bytes32', name: 's', type: 'bytes32' },
      ],
      name: 'buyWithPermit',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'offerToken', type: 'address' },
        { internalType: 'address', name: 'buyerToken', type: 'address' },
        { internalType: 'address', name: 'buyer', type: 'address' },
        { internalType: 'uint256', name: 'price', type: 'uint256' },
        { internalType: 'uint256', name: 'amount', type: 'uint256' },
      ],
      name: 'createOffer',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address[]', name: '_offerTokens', type: 'address[]' },
        { internalType: 'address[]', name: '_buyerTokens', type: 'address[]' },
        { internalType: 'address[]', name: '_buyers', type: 'address[]' },
        { internalType: 'uint256[]', name: '_prices', type: 'uint256[]' },
        { internalType: 'uint256[]', name: '_amounts', type: 'uint256[]' },
      ],
      name: 'createOfferBatch',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'offerToken', type: 'address' },
        { internalType: 'address', name: 'buyerToken', type: 'address' },
        { internalType: 'address', name: 'buyer', type: 'address' },
        { internalType: 'uint256', name: 'price', type: 'uint256' },
        { internalType: 'uint256', name: 'amount', type: 'uint256' },
        { internalType: 'uint256', name: 'newAllowance', type: 'uint256' },
        { internalType: 'uint256', name: 'deadline', type: 'uint256' },
        { internalType: 'uint8', name: 'v', type: 'uint8' },
        { internalType: 'bytes32', name: 'r', type: 'bytes32' },
        { internalType: 'bytes32', name: 's', type: 'bytes32' },
      ],
      name: 'createOfferWithPermit',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'uint256', name: 'offerId', type: 'uint256' }],
      name: 'deleteOffer',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'uint256[]', name: 'offerIds', type: 'uint256[]' }],
      name: 'deleteOfferBatch',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'uint256[]', name: 'offerIds', type: 'uint256[]' }],
      name: 'deleteOfferByAdmin',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    { inputs: [], name: 'fee', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function' },
    {
      inputs: [{ internalType: 'uint256', name: 'offerId', type: 'uint256' }],
      name: 'getInitialOffer',
      outputs: [
        { internalType: 'address', name: '', type: 'address' },
        { internalType: 'address', name: '', type: 'address' },
        { internalType: 'address', name: '', type: 'address' },
        { internalType: 'address', name: '', type: 'address' },
        { internalType: 'uint256', name: '', type: 'uint256' },
        { internalType: 'uint256', name: '', type: 'uint256' },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'getOfferCount',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'bytes32', name: 'role', type: 'bytes32' }],
      name: 'getRoleAdmin',
      outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: 'token', type: 'address' }],
      name: 'getTokenType',
      outputs: [{ internalType: 'enum IRealTokenYamUpgradeableV3.TokenType', name: '', type: 'uint8' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'bytes32', name: 'role', type: 'bytes32' },
        { internalType: 'address', name: 'account', type: 'address' },
      ],
      name: 'grantRole',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'bytes32', name: 'role', type: 'bytes32' },
        { internalType: 'address', name: 'account', type: 'address' },
      ],
      name: 'hasRole',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'admin_', type: 'address' },
        { internalType: 'address', name: 'moderator_', type: 'address' },
      ],
      name: 'initialize',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    { inputs: [], name: 'pause', outputs: [], stateMutability: 'nonpayable', type: 'function' },
    { inputs: [], name: 'paused', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], stateMutability: 'view', type: 'function' },
    {
      inputs: [
        { internalType: 'uint256', name: 'offerId', type: 'uint256' },
        { internalType: 'uint256', name: 'amount', type: 'uint256' },
      ],
      name: 'pricePreview',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [],
      name: 'proxiableUUID',
      outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'bytes32', name: 'role', type: 'bytes32' },
        { internalType: 'address', name: 'account', type: 'address' },
      ],
      name: 'renounceRole',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'bytes32', name: 'role', type: 'bytes32' },
        { internalType: 'address', name: 'account', type: 'address' },
      ],
      name: 'revokeRole',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: 'token', type: 'address' }],
      name: 'saveLostTokens',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'uint256', name: 'fee_', type: 'uint256' }],
      name: 'setFee',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'uint256', name: 'offerId', type: 'uint256' }],
      name: 'showOffer',
      outputs: [
        { internalType: 'address', name: '', type: 'address' },
        { internalType: 'address', name: '', type: 'address' },
        { internalType: 'address', name: '', type: 'address' },
        { internalType: 'address', name: '', type: 'address' },
        { internalType: 'uint256', name: '', type: 'uint256' },
        { internalType: 'uint256', name: '', type: 'uint256' },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'bytes4', name: 'interfaceId', type: 'bytes4' }],
      name: 'supportsInterface',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address[]', name: 'tokens_', type: 'address[]' },
        { internalType: 'enum IRealTokenYamUpgradeableV3.TokenType[]', name: 'types_', type: 'uint8[]' },
      ],
      name: 'toggleWhitelistWithType',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: 'tokenAddr', type: 'address' }],
      name: 'tokenInfo',
      outputs: [
        { internalType: 'uint256', name: '', type: 'uint256' },
        { internalType: 'string', name: '', type: 'string' },
        { internalType: 'string', name: '', type: 'string' },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    { inputs: [], name: 'unpause', outputs: [], stateMutability: 'nonpayable', type: 'function' },
    {
      inputs: [
        { internalType: 'uint256', name: 'offerId', type: 'uint256' },
        { internalType: 'uint256', name: 'price', type: 'uint256' },
        { internalType: 'uint256', name: 'amount', type: 'uint256' },
      ],
      name: 'updateOffer',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'uint256[]', name: '_offerIds', type: 'uint256[]' },
        { internalType: 'uint256[]', name: '_prices', type: 'uint256[]' },
        { internalType: 'uint256[]', name: '_amounts', type: 'uint256[]' },
      ],
      name: 'updateOfferBatch',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'uint256', name: 'offerId', type: 'uint256' },
        { internalType: 'uint256', name: 'price', type: 'uint256' },
        { internalType: 'uint256', name: 'amount', type: 'uint256' },
        { internalType: 'uint256', name: 'newAllowance', type: 'uint256' },
        { internalType: 'uint256', name: 'deadline', type: 'uint256' },
        { internalType: 'uint8', name: 'v', type: 'uint8' },
        { internalType: 'bytes32', name: 'r', type: 'bytes32' },
        { internalType: 'bytes32', name: 's', type: 'bytes32' },
      ],
      name: 'updateOfferWithPermit',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [{ internalType: 'address', name: 'newImplementation', type: 'address' }],
      name: 'upgradeTo',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        { internalType: 'address', name: 'newImplementation', type: 'address' },
        { internalType: 'bytes', name: 'data', type: 'bytes' },
      ],
      name: 'upgradeToAndCall',
      outputs: [],
      stateMutability: 'payable',
      type: 'function',
    },
  ];
  const swapcatABI = [
    {
      constant: true,
      inputs: [{ name: '_tokenaddr', type: 'address' }],
      name: 'tokeninfo',
      outputs: [
        { name: '', type: 'uint256' },
        { name: '', type: 'string' },
        { name: '', type: 'string' },
      ],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: false,
      inputs: [{ name: 'token', type: 'address' }],
      name: 'losttokens',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: false,
      inputs: [{ name: '_offerid', type: 'uint24' }],
      name: 'deleteoffer',
      outputs: [{ name: '', type: 'string' }],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        { name: '_offertoken', type: 'address' },
        { name: '_buyertoken', type: 'address' },
        { name: '_price', type: 'uint256' },
        { name: '_offerid', type: 'uint24' },
      ],
      name: 'makeoffer',
      outputs: [{ name: '', type: 'uint24' }],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ name: '_offerid', type: 'uint24' }],
      name: 'showoffer',
      outputs: [
        { name: '', type: 'address' },
        { name: '', type: 'address' },
        { name: '', type: 'address' },
        { name: '', type: 'uint256' },
        { name: '', type: 'uint256' },
      ],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: true,
      inputs: [
        { name: '_offerid', type: 'uint24' },
        { name: '_amount', type: 'uint256' },
      ],
      name: 'pricepreview',
      outputs: [{ name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    {
      constant: false,
      inputs: [
        { name: '_offerid', type: 'uint24' },
        { name: '_offertokenamount', type: 'uint256' },
        { name: '_price', type: 'uint256' },
      ],
      name: 'buy',
      outputs: [{ name: '', type: 'string' }],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      constant: true,
      inputs: [],
      name: 'getoffercount',
      outputs: [{ name: '', type: 'uint24' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    { payable: true, stateMutability: 'payable', type: 'fallback' },
  ];
  const ERC20ABI = [
    // BalanceOf function
    {
      constant: true,
      inputs: [{ name: '_owner', type: 'address' }],
      name: 'balanceOf',
      outputs: [{ name: 'balance', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    // Allowance function
    {
      constant: true,
      inputs: [
        { name: '_owner', type: 'address' },
        { name: '_spender', type: 'address' },
      ],
      name: 'allowance',
      outputs: [{ name: 'remaining', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    // Decimals function
    {
      constant: true,
      inputs: [],
      name: 'decimals',
      outputs: [{ name: '', type: 'uint8' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
    // Approve function
    {
      constant: false,
      inputs: [
        { name: '_spender', type: 'address' },
        { name: '_value', type: 'uint256' },
      ],
      name: 'approve',
      outputs: [{ name: '', type: 'bool' }],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function',
    },
  ];

  // const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const connect = async () => {
    if (isConnecting) return;
    // console.log("connect", window.ethereum);

    if (window.ethereum) {
      try {
        isConnecting = true;
        const web3Instance = new Web3(window.ethereum);
        setWeb3(web3Instance);

        // Demande l'accès au compte
        await window.ethereum.enable();
        const accounts = await web3Instance.eth.getAccounts();

        if (accounts.length === 0) {
          console.error('No accounts found');
          setWeb3Active(false);
          isConnecting = false;
          return false;
        } else {
          const walletConnected = accounts[0].toLowerCase();
          console.log('account', walletConnected);
          if (!user?.jwtoken) {
            try {
              let params = { main_wallet: walletConnected, wallet_connect: true };
              const userBalance = userTokensBalance.find((obj) => obj.label === 'Total');
              if (userBalance) params = { ...params, userBalance };
              console.log('connect params', params);
              fetch(`${WORKER_PITSBI_URL}connect`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(params), // Paramètre "caché" dans le corps
              }).then((reponse) => {
                if (reponse.ok) {
                  reponse.json().then((body) => {
                    console.log('user', body);
                    setUser(body);
                    let _holderWallets = holderWallets ? JSON.parse(JSON.stringify(holderWallets)) : [];
                    body.wallets.forEach((wallet, index) => {
                      if (!_holderWallets?.some((hWallet) => hWallet.address.toLowerCase() === wallet.toLowerCase())) {
                        _holderWallets.push({ address: wallet.toLowerCase(), checked: true, name: `RealT n°${index + 1}` });
                      }
                    });
                    setHolderWallets(_holderWallets);

                    const holderWallet = _holderWallets.filter((w) => w.address === accounts[0].toLowerCase());
                    if (holderWallet.length > 0) {
                      const name = holderWallet[0].name;
                      const address = `${holderWallet[0].address.slice(0, 6)}...${holderWallet[0].address.slice(-4)}`;
                      openSnackbar(`Web3 connected with account: ${name ? name : address}`, 'success');
                      console.log('Web3 connected with account:', name ? name : address, 'success');
                      setWeb3accountName(name ? name : `${address.slice(0, 6)}...${address.slice(-4)}`);

                      if (settingsWallet.selectedWallet !== holderWallet[0].address)
                        setSettingsWallet((prevSettings) => ({
                          ...prevSettings,
                          selectedWallet: holderWallet[0].address,
                        }));
                    }

                    setWeb3account(walletConnected);
                    setWeb3Active(true);
                  });
                }
              });
            } catch (error) {
              // TypeError: Failed to fetch
              console.log('login', '- fetch error :', error);
            }
          } else {
            try {
              let params = { main_wallet: walletConnected, wallet_connect: true };
              const userBalance = userTokensBalance.find((obj) => obj.label === 'Total');
              if (userBalance) params = { ...params, userBalance };
              fetch(`${WORKER_PITSBI_URL}connect`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(params), // Paramètre "caché" dans le corps
              }).then((reponse) => {
                console.log('reponse', reponse);
                if (reponse.ok) {
                  reponse.json().then((body) => {
                    console.log('user', body);
                    setUser(body);
                    let _holderWallets = holderWallets ? JSON.parse(JSON.stringify(holderWallets)) : [];
                    body.wallets.forEach((wallet, index) => {
                      if (!_holderWallets?.some((hWallet) => hWallet.address.toLowerCase() === wallet.toLowerCase())) {
                        _holderWallets.push({ address: wallet.toLowerCase(), checked: true, name: `RealT n°${index + 1}` });
                      }
                    });
                    setHolderWallets(_holderWallets);

                    const holderWallet = _holderWallets.filter((w) => w.address === accounts[0].toLowerCase());
                    if (holderWallet.length > 0) {
                      const name = holderWallet[0].name;
                      const address = `${holderWallet[0].address.slice(0, 6)}...${holderWallet[0].address.slice(-4)}`;
                      openSnackbar(`Web3 connected with account: ${name ? name : address}`, 'success');
                      console.log('Web3 connected with account:', name ? name : address);
                      setWeb3accountName(name ? name : `${address.slice(0, 6)}...${address.slice(-4)}`);

                      if (settingsWallet.selectedWallet !== holderWallet[0].address)
                        setSettingsWallet((prevSettings) => ({
                          ...prevSettings,
                          selectedWallet: holderWallet[0].address,
                        }));
                    }

                    setWeb3account(walletConnected);
                    setWeb3Active(true);
                  });
                }
              });
            } catch (error) {
              // TypeError: Failed to fetch
              console.log('connect', '- fetch error :', error);
            }
          }
        }

        // Switch network
        await selectNetwork();

        isConnecting = false;
        return true;
      } catch (error) {
        if (error.code === 4001) {
          console.log('User rejected the connection request.');
          // if (!web3active)
          // 	setTimeout(() => {
          // 		connect();
          // 	}, 1000);
        } else {
          openSnackbar(`Error during connect: ${error.message}`, 'error');
          console.error('Error during connect:', error);
          setWeb3Active(false);
        }
      } finally {
        isConnecting = false;
      }
    } else {
      console.log('Non-Ethereum browser detected. You should consider trying MetaMask!');
      setWeb3Active(false);
    }
    isConnecting = false;
    return false;
  };

  const disconnect = () => {
    setWeb3(null);
    setWeb3account(null);
    setWeb3accountName(null);
    setWeb3Active(false);
    console.log('Web3 disconnected');
  };

  const changeNetwork = async (chainId) => {
    if (window.ethereum) {
      if (settingsWallet && blockchainsClient) {
        const blockchainsArray = Object.entries(blockchainsClient).map(([id, data]) => ({
          ...data,
        }));
        const blockchainClient = blockchainsArray.filter((b) => b.chainId === hexToInt(chainId));
        if (blockchainClient.length > 0) {
          setSettingsWallet((prevSettings) => ({
            ...prevSettings,
            selectedBlockchain: blockchainClient[0].name,
          }));
        } else {
          setSettingsWallet((prevSettings) => ({
            ...prevSettings,
            selectedBlockchain: '',
          }));
        }
      }
    }
  };

  const selectNetwork = async () => {
    if (window.ethereum) {
      if (settingsWallet && blockchainsClient) {
        if (settingsWallet.selectedBlockchain) {
          try {
            const chainId = intToHex(blockchainsClient[settingsWallet.selectedBlockchain].chainId);
            await window.ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId }],
            });
          } catch (error) {
            if (error.code === 4902) {
              console.log("Le réseau n'est pas ajouté dans MetaMask.");
            } else {
              console.error('Erreur lors du changement de réseau', error);
            }
          }
        }
      }
    }
  };

  const getBalance = async () => {
    if (web3 && web3account) {
      try {
        const balance = await web3.eth.getBalance(web3account);
        return balance;
      } catch (error) {
        console.error('Error while fetching the balance:', error);
        return null;
      }
    }
    return null;
  };

  const getTokenBalance = async (tokenAddress) => {
    if (web3 && web3account) {
      try {
        const tokenContract = new web3.eth.Contract(ERC20ABI, tokenAddress);
        console.error('tokenContract:', tokenContract);

        // Récupérer le solde en tant que nombre
        const balance = parseFloat(await tokenContract.methods.balanceOf(web3account).call());
        console.error('balance:', balance);

        // Récupérer les décimales en tant que nombre
        const decimals = parseInt(await tokenContract.methods.decimals().call(), 10);
        console.error('decimals:', decimals);

        // Calculer le solde formaté
        const formattedBalance = balance / Math.pow(10, decimals);
        console.error('formattedBalance:', formattedBalance);

        return formattedBalance;
      } catch (error) {
        console.error('Error while fetching the balance:', error);
        return null;
      }
    }
    return null;
  };


  const tokenApprove = async (tokenToApprove, newAllowance, spenderAddress, callback, coef=1.001) => {    
    let connected = web3active;
    if (!web3active) {
      connected = await connect();
      // await delay(3000);
    }
    
    if (connected && web3) {
      try {
        const smartContract = new web3.eth.Contract(ERC20ABI, tokenToApprove);
        const digit = Number(await smartContract.methods.decimals().call());
        const balance = await getTokenBalance(tokenToApprove);
        const allowance = Math.round(newAllowance * 10 ** digit * coef);
        
        console.log("tokenToApprove",tokenToApprove, newAllowance, balance, spenderAddress, callback);

        // Appel de la méthode approve avec une nouvelle allowance valide
        const result = await smartContract.methods.approve(spenderAddress, allowance).send({ from: web3account });
        callback(result);
        return;
      } catch (error) {
        console.error('Error during tokenApprove:', error);
        openSnackbar(`Error during tokenApprove: ${error}`, 'error');
      }
    }
    callback(null);
  };

  const tokenBuySwapCat = async (offer, liveOffer, tokenAmount, callback) => {
    // console.log(offer, liveOffer, tokenAmount);

    let connected = web3active;
    if (!web3active) {
      connected = await connect();
      // await delay(3000);
    }

    if (connected && web3) {
      try {
        // _offerid (uint24)
        // _offertokenamount (uint256)
        // _price (uint256)
        const smartContract_token_to_sell = new web3.eth.Contract(ERC20ABI, offer.token_to_sell);
        // const smartContract_token_to_pay = new web3.eth.Contract(ERC20ABI, offer.token_to_pay);
        const smartContract = new web3.eth.Contract(swapcatABI, swapcatAddress);
        const digit_to_sell = Number(await smartContract_token_to_sell.methods.decimals().call());
        // const digit_to_pay = Number(await smartContract_token_to_pay.methods.decimals().call());

        const _offerid = offer.id_offer;
        const _offertokenamount = Math.round(tokenAmount * 10 ** digit_to_sell);
        const _price = liveOffer.priceInOffer;

        console.log('tokenBuySwapCat', _offerid, '_price', _price, '_offertokenamount', _offertokenamount);

        const result = await smartContract.methods.buy(_offerid, _offertokenamount, _price).send({ from: web3account });
        openSnackbar(`New realToken buy successfully: ${offer.token.shortName}`, 'success');
        callback(result);
        return null;
      } catch (error) {
        console.error('Error during tokenBuySwapCat:', error);
        openSnackbar(`Error during tokenBuySwapCat: ${error}`, 'error');
      }
    }
    callback(null);
    return null;
  };

  const tokenBuyYam = async (offer, liveOffer, tokenAmount, callback) => {
    // console.log(offer, liveOffer, tokenAmount);

    let connected = web3active;
    if (!web3active) {
      connected = await connect();
      // await delay(3000);
    }

    if (connected && web3) {
      try {
        // _offerid (uint24)
        // _price (uint256)
        // _offertokenamount (uint256)
        const smartContract_token_to_sell = new web3.eth.Contract(ERC20ABI, offer.token_to_sell);
        // const smartContract_token_to_pay = new web3.eth.Contract(ERC20ABI, offer.token_to_pay);
        const smartContract = new web3.eth.Contract(yamABI, yamAddress);
        const digit_to_sell = Number(await smartContract_token_to_sell.methods.decimals().call());
        // const digit_to_pay = Number(await smartContract_token_to_pay.methods.decimals().call());

        const _offerid = offer.id_offer;
        const _price = liveOffer.priceInOffer;
        const _offertokenamount = Math.round(tokenAmount * 10 ** digit_to_sell);

        console.log('tokenBuyYam', _offerid, '_price', _price, '_offertokenamount', _offertokenamount);

        const result = await smartContract.methods.buy(_offerid, _price, _offertokenamount).send({ from: web3account });
        openSnackbar(`New realToken buy successfully: ${offer.token.shortName}`, 'success');
        callback(result);
        return null;
      } catch (error) {
        console.error('Error during tokenBuyYam:', error);
        openSnackbar(`Error during tokenBuyYam: ${error}`, 'error');
      }
    }
    callback(null);
    return null;
  };

  const tokenSellSwapCat = async (offer, liveOffer, tokenAmount, callback) => {
    // console.log(offer, liveOffer, tokenAmount);

    let connected = web3active;
    if (!web3active) {
      connected = await connect();
      // await delay(3000);
    }

    if (connected && web3) {
      try {
        // _offerid (uint24)
        // _offertokenamount (uint256)
        // _price (uint256)
        // const smartContract_token_to_buy = new web3.eth.Contract(ERC20ABI, offer.token_to_buy);
        const smartContract_token_to_pay = new web3.eth.Contract(ERC20ABI, offer.token_to_pay);
        const smartContract = new web3.eth.Contract(swapcatABI, swapcatAddress);
        // const digit_to_buy = Number(await smartContract_token_to_buy.methods.decimals().call());
        const digit_to_pay = Number(await smartContract_token_to_pay.methods.decimals().call());

        const _offerid = offer.id_offer;
        const _price = liveOffer.priceInOffer;
        const _offertokenamount = Math.round((tokenAmount / liveOffer.price) * 10 ** digit_to_pay);

        console.log('tokenSellSwapCat', _offerid, '_price', _price, '_offertokenamount', _offertokenamount);

        const result = await smartContract.methods.buy(_offerid, _offertokenamount, _price).send({ from: web3account });
        openSnackbar(`New realToken buy successfully: ${offer.token.shortName}`, 'success');
        callback(result);
        return null;
      } catch (error) {
        console.error('Error during tokenSellSwapCat:', error);
        openSnackbar(`Error during tokenSellSwapCat: ${error}`, 'error');
      }
    }
    callback(null);
    return null;
  };

  const tokenSellYam = async (offer, liveOffer, tokenAmount, callback) => {
    // console.log("tokenSellYam", offer, liveOffer, tokenAmount);

    let connected = web3active;
    if (!web3active) {
      connected = await connect();
      // await delay(3000);
    }

    if (connected && web3) {
      try {
        // _offerid (uint24)
        // _price (uint256)
        // _offertokenamount (uint256)
        // const smartContract_token_to_buy = new web3.eth.Contract(ERC20ABI, offer.token_to_buy);
        const smartContract_token_to_pay = new web3.eth.Contract(ERC20ABI, offer.token_to_pay);
        const smartContract = new web3.eth.Contract(yamABI, yamAddress);
        // const digit_to_buy = Number(await smartContract_token_to_buy.methods.decimals().call());
        const digit_to_pay = Number(await smartContract_token_to_pay.methods.decimals().call());

        const _offerid = offer.id_offer;
        const _price = liveOffer.priceInOffer;
        const _offertokenamount = Math.round((tokenAmount / liveOffer.price) * 10 ** digit_to_pay);

        console.log('tokenSellYam', _offerid, '_price', _price, '_offertokenamount', _offertokenamount);

        const result = await smartContract.methods.buy(_offerid, _price, _offertokenamount).send({ from: web3account });
        openSnackbar(`New realToken buy successfully: ${offer.token.shortName}`, 'success');
        callback(result);
        return null;
      } catch (error) {
        console.error('Error during tokenSellYam:', error);
        openSnackbar(`Error during tokenSellYam: ${error}`, 'error');
      }
    }
    callback(null);
    return null;
  };

  const deleteSwapCat = async (_offerid, callback) => {
    let connected = web3active;
    if (!web3active) {
      connected = await connect();
      // await delay(3000);
    }

    if (connected && web3) {
      try {
        // _offerid (uint24)
        const smartContract = new web3.eth.Contract(swapcatABI, swapcatAddress);

        // console.log("deleteSwapCat", _offerid);

        const result = await smartContract.methods.deleteoffer(_offerid).send({ from: web3account });
        openSnackbar(`Delete SwapCat Offer successfully: n°${_offerid}`, 'success');
        callback(result);
        return null;
      } catch (error) {
        console.error('Error during deleteSwapCat:', error);
        openSnackbar(`Error during deleteSwapCat: ${error}`, 'error');
      }
    }
    callback(null);
    return null;
  };

  const deleteYam = async (_offerid, callback) => {
    let connected = web3active;
    if (!web3active) {
      connected = await connect();
      // await delay(3000);
    }

    if (connected && web3) {
      try {
        // _offerid (uint24)
        const smartContract = new web3.eth.Contract(yamABI, yamAddress);

        // console.log("deleteYam", _offerid);

        const result = await smartContract.methods.deleteOffer(_offerid).send({ from: web3account });
        openSnackbar(`Delete Yam Offer successfully: n°${_offerid}`, 'success');
        callback(result);
        return null;
      } catch (error) {
        console.error('Error during deleteYam :', error);
        openSnackbar(`Error during deleteYam: ${error}`, 'error');
      }
    }
    callback(null);
    return null;
  };

  const deleteYamBatch = async (_offerIds, callback) => {
    let connected = web3active;
    if (!web3active) {
      connected = await connect();
      // await delay(3000);
    }

    if (connected && web3) {
      try {
        // offerIds (uint256[])
        const smartContract = new web3.eth.Contract(yamABI, yamAddress);
        // console.log("deleteYam", _offerIds);

        const result = await smartContract.methods.deleteOfferBatch(_offerIds).send({ from: web3account });
        openSnackbar(`Delete Yam Offer Batch successfully: ${_offerIds.length} offers`, 'success');
        callback(result);
        return null;
      } catch (error) {
        console.error('Error during deleteYamBatch :', error);
        openSnackbar(`Error during deleteYamBatch: ${error}`, 'error');
      }
    }
    callback(null);
    return null;
  };

  const createSwapcat = async (_offertoken, _buyertoken, _price, _offerid, callback) => {
    let connected = web3active;
    if (!web3active) {
      connected = await connect();
      // await delay(3000);
    }

    if (connected && web3) {
      try {
        // _price (uint256)
        const smartContract = new web3.eth.Contract(swapcatABI, swapcatAddress);
        const smartContract_buyertoken = new web3.eth.Contract(ERC20ABI, _buyertoken);
        const digit_buyertoken = Number(await smartContract_buyertoken.methods.decimals().call());
        const price = Math.round(_price * 10 ** digit_buyertoken);

        // console.log("createSwapCat", _offertoken, _buyertoken, price, _offerid);

        const result = await smartContract.methods.makeoffer(_offertoken, _buyertoken, price, _offerid).send({ from: web3account });
        if (_offerid === 0) openSnackbar(`Create new SwapCat Offer successfully`, 'success');
        else openSnackbar(`Modify SwapCat Offer successfully: n°${_offerid}`, 'success');
        callback(result);
        return null;
      } catch (error) {
        console.error('Error during createSwapcat:', error);
        openSnackbar(`Error during createSwapcat: ${error}`, 'error');
      }
    }
    callback(null);
    return null;
  };

  const createYam = async (_offertoken, _buyertoken, _buyerholder, _price, _amount, _offerid, callback) => {
    let connected = web3active;
    if (!web3active) {
      connected = await connect();
      // await delay(3000);
    }

    if (connected && web3) {
      try {
        // _price (uint256)
        const smartContract = new web3.eth.Contract(yamABI, yamAddress);
        const smartContract_offertoken = new web3.eth.Contract(ERC20ABI, _offertoken);
        const smartContract_buyertoken = new web3.eth.Contract(ERC20ABI, _buyertoken);
        const digit_offertoken = Number(await smartContract_offertoken.methods.decimals().call());
        const digit_buyertoken = Number(await smartContract_buyertoken.methods.decimals().call());
        const amount = Math.round(_amount * 10 ** digit_offertoken);
        const price = Math.round(_price * 10 ** digit_buyertoken);

        // console.log("createYam", _offertoken, _buyertoken, _buyerholder, _price, _amount, _offerid, callback);

        if (_offerid === 0) {
          const result = await smartContract.methods.createOffer(_offertoken, _buyertoken, _buyerholder, price, amount).send({ from: web3account });
          openSnackbar(`Create new Yam Offer successfully`, 'success');
          callback(result);
          return null;
        } else {
          console.log('createYam', _offerid, _price, _amount);
          console.log('createYam', _offerid, digit_offertoken, digit_buyertoken);
          console.log('createYam', _offerid, price, _amount);
          const result = await smartContract.methods.updateOffer(_offerid, price, amount).send({ from: web3account });
          openSnackbar(`Modify Yam Offer successfully: n°${_offerid}`, 'success');
          callback(result);
          return null;
        }
      } catch (error) {
        console.error('Error during createYam:', error);
        openSnackbar(`Error during createYam: ${error}`, 'error');
      }
    }
    callback(null);
    return null;
  };

  const createYamBatch = async (offerTokens, buyerTokens, buyers, prices, amounts, callback) => {
    let connected = web3active;
    if (!web3active) {
      connected = await connect();
    }

    if (connected && web3 && offerTokens.length > 0) {
      // Vérifier que tous les tableaux ont la même taille
      const length = offerTokens.length;
      if (length !== buyerTokens.length || length !== buyers.length || length !== prices.length || length !== amounts.length) {
        const errorMsg = 'Error: All input arrays must have the same length.';
        console.error(errorMsg);
        openSnackbar(errorMsg, 'error');
        callback(null);
        return null;
      }
      try {
        const _offertoken = offerTokens;
        const _buyertoken = buyerTokens;
        const _buyerholder = buyers;

        // Préparation des tableaux pour les prix et montants convertis
        const _pricesConverted = [];
        const _amountsConverted = [];

        // Parcourir les tableaux pour effectuer les conversions
        for (let i = 0; i < offerTokens.length; i++) {
          // Récupérer les décimales pour chaque token
          const smartContract_offertoken = new web3.eth.Contract(ERC20ABI, _offertoken[i]);
          const smartContract_buyertoken = new web3.eth.Contract(ERC20ABI, _buyertoken[i]);

          const digit_offertoken = Number(await smartContract_offertoken.methods.decimals().call());
          const digit_buyertoken = Number(await smartContract_buyertoken.methods.decimals().call());

          // Convertir le montant et le prix avec les décimales appropriées
          const convertedAmount = Math.round(amounts[i] * 10 ** digit_offertoken);
          const convertedPrice = Math.round(prices[i] * 10 ** digit_buyertoken);

          _amountsConverted.push(convertedAmount);
          _pricesConverted.push(convertedPrice);
        }

        // Créer la liste des nouvelles offres avec la méthode batch
        const smartContract = new web3.eth.Contract(yamABI, yamAddress);
        const result = await smartContract.methods
          .createOfferBatch(_offertoken, _buyertoken, _buyerholder, _pricesConverted, _amountsConverted)
          .send({ from: web3account });

        openSnackbar(`Create new Yam Offer successfully`, 'success');
        callback(result);
        return null;
      } catch (error) {
        console.error('Error during createYamBatch:', error);
        openSnackbar(`Error during createYamBatch: ${error.message}`, 'error');
      }
    }
    callback(null);
    return null;
  };

  const updateYamBatch = async (offerIds, offerTokens, buyerTokens, prices, amounts, callback) => {
    let connected = web3active;
    if (!web3active) {
      connected = await connect();
    }

    if (connected && web3 && offerTokens.length > 0) {
      // Vérifier que tous les tableaux ont la même taille
      const length = offerTokens.length;
      if (length !== buyerTokens.length || length !== offerIds.length || length !== prices.length || length !== amounts.length) {
        const errorMsg = 'Error: All input arrays must have the same length.';
        console.error(errorMsg);
        openSnackbar(errorMsg, 'error');
        callback(null);
        return null;
      }
      try {
        const _offertoken = offerTokens;
        const _buyertoken = buyerTokens;
        // const _buyerholder = buyers;
        const _offerIds = offerIds;

        // Préparation des tableaux pour les prix et montants convertis
        const _pricesConverted = [];
        const _amountsConverted = [];

        // Parcourir les tableaux pour effectuer les conversions
        for (let i = 0; i < offerTokens.length; i++) {
          setProgress(`Processing ${i + 1} of ${offerTokens.length} offers...`);

          // Récupérer les adresses des tokens
          const offertokenAddress = _offertoken[i];
          const buyertokenAddress = _buyertoken[i];

          // Vérifier si le token est un RealToken ou une Coin
          const isRealTokenOffertoken = isRealToken(offertokenAddress) || isCoinBlockChain(offertokenAddress);
          const isCoinBuyertoken = isRealToken(buyertokenAddress) || isCoinBlockChain(buyertokenAddress);

          console.log('isRealTokenOffertoken', isRealTokenOffertoken, 'isCoinBuyertoken', isCoinBuyertoken);

          // Si ce n'est ni un RealToken ni une Coin, récupérer les décimales via le smart contract
          const digit_offertoken = isRealTokenOffertoken
            ? isRealTokenOffertoken // RealToken, utiliser sa valeur
            : Number(await new web3.eth.Contract(ERC20ABI, offertokenAddress).methods.decimals().call()); // Sinon, récupérer du smart contract

          const digit_buyertoken = isCoinBuyertoken
            ? isCoinBuyertoken // Coin, utiliser sa valeur
            : Number(await new web3.eth.Contract(ERC20ABI, buyertokenAddress).methods.decimals().call()); // Sinon, récupérer du smart contract

          // Convertir le montant et le prix avec les décimales appropriées
          const convertedAmount = Math.round(amounts[i] * 10 ** digit_offertoken);
          const convertedPrice = Math.round(prices[i] * 10 ** digit_buyertoken);

          _amountsConverted.push(convertedAmount);
          _pricesConverted.push(convertedPrice);
        }
        setProgress(`Validate Tx to send ${offerTokens.length} Update Offers`);

        // Créer la liste des nouvelles offres avec la méthode batch
        const smartContract = new web3.eth.Contract(yamABI, yamAddress);
        const result = await smartContract.methods.updateOfferBatch(_offerIds, _pricesConverted, _amountsConverted).send({ from: web3account });

        openSnackbar(`Update Yam Offer Batch successfully`, 'success');
        setProgress(null); // Efface la progression en cas d'erreur
        callback(result);
        return null;
      } catch (error) {
        console.error('Error during updateYamBatch:', error);
        openSnackbar(`Error during updateYamBatch: ${error.message}`, 'error');
      }
    }
    setProgress(null); // Efface la progression en cas d'erreur
    callback(null);
    return null;
  };

  const sendTransaction = async (to, value) => {
    if (web3 && web3account) {
      try {
        const tx = {
          from: web3account,
          to: to,
          value: web3.utils.toWei(value, 'ether'),
        };
        const result = await web3.eth.sendTransaction(tx);
        return result;
      } catch (error) {
        console.error("Erreur lors de l'envoi de la transaction", error);
        return null;
      }
    }
    return null;
  };

  const marketSwapCatHolderUpdate = async () => {
    try {
      let request = WORKER_PITSBI_URL + 'swapcat_holder_update?wallet=' + web3account;
      let response = await fetch(request);
      if (response.ok) {
        let body = await response.json();
        console.log('marketSwapCatHolderUpdate', web3account, body);
        openSnackbar(body.response, body.status);
      }
    } catch (error) {
      console.log('marketSwapCatHolderUpdate - fetch error: wallet:', error);
    }
  };

  const marketYamHolderUpdate = async () => {
    try {
      let request = WORKER_PITSBI_URL + 'yam_holder_update?wallet=' + web3account;
      let response = await fetch(request);
      if (response.ok) {
        let body = await response.json();
        console.log('marketYamHolderUpdate', web3account, body);
        openSnackbar(body.response, body.status);
      }
    } catch (error) {
      console.log('marketYamHolderUpdate - fetch error: wallet:', error);
    }
  };

  // useEffect(() => {
  //   const interval = setInterval(async () => {
  //     if (web3) {
  //       try {
  //         const accounts = await web3.eth.getAccounts();
  //         if (web3active && accounts.length === 0) {
  //           console.log('Wallet is locked');
  //           setWeb3Active(false);
  //           setWeb3account(null);
  //           setWeb3accountName(null);
  //         }
  //         if (web3active && web3account!=accounts[0]) {
  //           connect()
  //         }
  //         if (!web3active  && accounts.length > 0) {
  //           console.log('Wallet is unlocked');
  //           setWeb3Active(true);
  //           setWeb3account(accounts[0]);
  //           if (settingsWallet.selectedWallet !== accounts[0])
  //                       setSettingsWallet((prevSettings) => ({
  //                         ...prevSettings,
  //                         selectedWallet: accounts[0],
  //                       }));
  //         }
  //       } catch (error) {
  //         console.error('Error checking wallet status:', error);
  //       }
  //     }
  //   }, 1 * 60 * 1000); // Vérifie toutes les 5 minutes

  //   return () => clearInterval(interval);
  // }, []);

  useEffect(() => {
    if (window.ethereum) {
      const handleChainChanged = (chainId) => {
        console.log('chainChanged', chainId);
        changeNetwork(chainId);
      };

      const handleAccountsChanged = async () => {
        console.log('accountsChanged', window.ethereum);
        try {
          const web3Instance = new Web3(window.ethereum);
          setWeb3(web3Instance);
          const accounts = await web3.eth.getAccounts();
          if (!web3active && accounts.length > 0) {
            console.log('change Selected Wallet', accounts[0]);
            setWeb3account(accounts[0]);
            const walletConnected = accounts[0].toLowerCase();
            if (settingsWallet.selectedWallet !== walletConnected)
              setSettingsWallet((prevSettings) => ({
                ...prevSettings,
                selectedWallet: walletConnected,
              }));
          } else if (accounts.length === 0) {
            console.log('Wallet is locked or disconnected');
            setWeb3Active(false);
          }
          if (web3active) connect();
        } catch (error) {
          console.error('Error fetching accounts:', error);
        }
      };

      const handleDisconnect = () => {
        console.log('disconnect', window.ethereum);
        setWeb3Active(false);
      };

      window.ethereum.on('chainChanged', handleChainChanged);
      window.ethereum.on('accountsChanged', handleAccountsChanged);
      window.ethereum.on('disconnect', handleDisconnect);

      return () => {
        // Clean up listeners to prevent memory leaks
        window.ethereum.removeListener('chainChanged', handleChainChanged);
        window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
        window.ethereum.removeListener('disconnect', handleDisconnect);
      };
    }
  }, [web3active, connect, changeNetwork,]);

  useEffect(() => {
    selectNetwork();
  }, [settingsWallet.selectedBlockchain]);

  const value = {
    web3,
    web3account,
    web3accountName,
    web3active,
    progress,
    user,
    web3smartContract: {
      yamAddress,
      swapcatAddress,
      yamABI,
      swapcatABI,
      ERC20ABI,
    },
    web3functions: {
      connect,
      disconnect,
      selectNetwork,
      getBalance,
      getTokenBalance,
      tokenApprove,
      tokenBuyYam,
      tokenBuySwapCat,
      tokenSellSwapCat,
      tokenSellYam,
      deleteSwapCat,
      deleteYam,
      deleteYamBatch,
      createSwapcat,
      createYam,
      createYamBatch,
      updateYamBatch,
      marketSwapCatHolderUpdate,
      marketYamHolderUpdate,
      sendTransaction,
      setProgress,
      setUser,
    },
  };

  return (
    <Web3Context.Provider value={value}>
      {children}
      {/* Popup pour les messages */}
      <ProgressPopup message={progress} />

      {/* Bouton pour connecter le wallet */}
      {/* <ConnectWalletButton web3active={web3active} onConnectWallet={connect} message={progress} /> */}
    </Web3Context.Provider>
  );
};
