import React, { useContext, useState } from 'react';
import { SettingsContext } from '../../../context/settings-context';
import { MyThemeContext } from '../../../context/theme-context';

import AddchartOutlinedIcon from '@mui/icons-material/AddchartOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box, Paper, TableCell, TableRow, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { green, red } from '@mui/material/colors';

import FormatedNumber from '../../Common/Formater/FormatedNumber';
import FormatedPercentage from '../../Common/Formater/FormatedPercentage';
import FormatedPrice from '../../Common/Formater/FormatedPrice';

import TokenInfos from '../../Common/TokenInfos';
import WalletSalesOfferRow from './WalletSalesOfferRow';

const SIZE_ICON = 20;

function WalletSalesOffersBody({ token, index }) {
  const { settingsMarket, settingsWallet, blockchainsClient } = useContext(SettingsContext);
  const { DEFAULT_PROPERTY_TYPE } = useContext(SettingsContext);
  const { themeContext } = useContext(MyThemeContext);
  const [timerId, setTimerId] = useState(null);
  const [open, setOpen] = useState(false);
  const [openInfo, setOpenInfo] = useState(false);

  const isMatch = useMediaQuery(themeContext.breakpoints.down('lg'));

  // if (token.newOffersList && token.newOffersList.length > 0) console.log(token);
  // if (token.shortName === "13835 La Salle") console.log(token);

  if (!token)
    return (
      <Paper key='sortedOffers' elevation={3} sx={{ m: 1, mt: 15, border: 3, borderColor: 'secondary.main', borderRadius: 4 }}>
        <Box sx={{ my: 5, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          {/* <CircularProgress sx={{ m: 2, color: "secondary.main" }} size='2rem' /> */}
          <Typography variant='h5' sx={{ mx: 10, my: 1, color: 'secondary.main' }}>
            Waiting Offers Body synchronisation ... Or no wallet define !
          </Typography>
        </Box>
      </Paper>
    );

  const nbOffer = token.offersList.length + token.newOffersList?.length;
  const rowSpan = nbOffer > 0 ? nbOffer : 1;

  const showPosition =
    token.positionWallet < token.position
      ? `${token.positionWallet < 0.01 ? token.positionWallet.toFixed(4) : token.positionWallet.toFixed(2)} (${token.position.toFixed(2)})`
      : `${token.positionWallet < 0.01 ? token.positionWallet.toFixed(4) : token.positionWallet.toFixed(2)}`;

  console.log("WalletSalesOffersBody", token);

  return (
    <>
      {openInfo && <TokenInfos open={openInfo} setOpen={setOpenInfo} token={token} />}
      <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell
          rowSpan={rowSpan}
          align='center'
          sx={{ pl: 0, pr: 0, py: 1, cursor: 'pointer' }}
          component='td'
          scope='row'
          onClick={() => window.open(token.marketplaceLink, '_blank')}>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            <OpenInNewIcon color={'primary'} />
          </Box>
        </TableCell>

        <TableCell rowSpan={rowSpan} align='center' sx={{ pl: 0, pr: 0, py: 1, cursor: 'pointer' }} component='td' scope='row' onClick={() => setOpenInfo(true)}>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            <AddchartOutlinedIcon color={'primary'} />
          </Box>
        </TableCell>

        <TableCell rowSpan={rowSpan} align='left' sx={{ pl: 1, pr: 0, py: 1 }}>
          <Tooltip title={token.fullName} placement='top'>
            <Typography variant='body2' sx={{ color: token.whiteList ? green[500] : red[700] }}>
              {token.shortName}
              {/* {token.shortName} ({rowSpan}) */}
            </Typography>
          </Tooltip>
        </TableCell>

        {settingsMarket.WalletSaleOffers.showPosition && (
          <TableCell rowSpan={rowSpan} align='right' sx={{ pl: 1, pr: 0, py: 1 }}>
            <Typography variant='body2' sx={{ color: 'primary.main' }}>
              {showPosition}
            </Typography>
          </TableCell>
        )}

        <TableCell rowSpan={rowSpan} align='right' sx={{ pl: 1, pr: 0, py: 1 }}>
          <FormatedPrice value={token.tokenPrice} color={'primary.main'} />
        </TableCell>

        <TableCell rowSpan={rowSpan} align='right' sx={{ pl: 1, pr: 0, py: 1 }}>
          <FormatedPercentage value={token.annualPercentageYield / 100} color={'primary.main'} />
        </TableCell>

        {settingsMarket.WalletSaleOffers.showRented && (
          <Tooltip title={`Nb Unit(s) ${token.rentedUnits}/${token.totalUnits}`} placement='top'>
            <TableCell rowSpan={rowSpan} align='right' sx={{ pl: 1, pr: 0, py: 1 }}>
              <FormatedPercentage value={token.rentedUnits / token.totalUnits} color={token.token_rented < 1 ? red[700] : green[500]} />
            </TableCell>
          </Tooltip>
        )}

        {settingsMarket.WalletSaleOffers.showRentStart && (
          <TableCell rowSpan={rowSpan} align='right' sx={{ pl: 1, pr: 0, py: 1 }}>
            <Typography variant='body2'>{token.rent_start.slice(0, 10)}</Typography>
          </TableCell>
        )}

        <TableCell rowSpan={rowSpan} align='center' sx={{ pl: 1, pr: 1, py: 1 }}>
          <FormatedNumber value={token.nbOffers} color={'primary.main'} digit={0} />
        </TableCell>

        {token.offersList.length > 0 && (
          <>
            {token.offersList.slice(0, 1).map((offer) => (
              <WalletSalesOfferRow 
                key={`real-${offer.id_offer || index}`}  // Ajout d'une clé unique
                offer={offer} 
                index={index} 
                token={token} 
                add={true} 
              />
            ))}
          </>
        )}

        {!token.offersList.length > 0 && token.newOffersList.length > 0 && (
          <>
            {token.newOffersList.slice(0, 1).map((offer, index) => (
              <>
                <WalletSalesOfferRow key={`new:${index + 1}`} offer={offer} index={index} token={token} add={true} />
              </>
            ))}
          </>
        )}
        {token.offersList.length === 0 && token.newOffersList.length === 0 && (
          <>
            <WalletSalesOfferRow key={`add:${index + 1}`} offer={null} index={0} token={token} add={true} />
          </>
        )}
      </TableRow>

      {token.offersList.length > 1 && (
        <>
          {token.offersList.slice(1).map((offer, index) => (
            <>
              <TableRow key={`add:${index + 1}`}>
                <WalletSalesOfferRow key={`real:${index + 1}`} offer={offer} index={index} token={token} />
              </TableRow>
            </>
          ))}
        </>
      )}

      {token.offersList.length > 0
        ? token.newOffersList.length > 0 && (
            <>
              {token.newOffersList.map((offer, index) => (
                <>
                  <TableRow>
                    <WalletSalesOfferRow key={`new:${index + 1}`} offer={offer} index={index} token={token} />
                  </TableRow>
                </>
              ))}
            </>
          )
        : token.newOffersList.length > 1 && (
            <>
              {token.newOffersList.slice(1).map((offer, index) => (
                <>
                  <TableRow>
                    <WalletSalesOfferRow key={`new:${index + 1}`} offer={offer} index={index} token={token} />
                  </TableRow>
                </>
              ))}
            </>
          )}
    </>
  );
}

export default WalletSalesOffersBody;
