import React, { useContext, useEffect, useState } from 'react';
import { SettingsContext } from '../../../context/settings-context';

import { Box, Button, ButtonGroup, CircularProgress, Paper, TextField, Typography } from '@mui/material';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

function LineChartTokenRent() {
  const { realTokens, balanceWallets, holderWallets } = useContext(SettingsContext);
  const [tokens, setTokens] = useState(null);
  const [listData, setListData] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [visibleLines, setVisibleLines] = useState(['Rent']);

  useEffect(() => {
    if (realTokens) {
      const realtokensList = JSON.parse(JSON.stringify(realTokens.list));
      if (realtokensList) {
        let tokens = realtokensList.filter((data) => !data.shortName.startsWith('OLD') && data.totalTokens > 0 && data.productType !== 'equity_token');

        if (balanceWallets) {
          tokens.forEach((token) => {
            token.position = 0;
            holderWallets
              .filter((holderWallet) => holderWallet.checked)
              .forEach((holderWallet) => {
                for (const wallet of Object.keys(balanceWallets)) {
                  if (holderWallet.address === wallet) {
                    for (const chain of Object.keys(balanceWallets[wallet].tokens)) {
                      if (chain === 'eth') {
                        // Vérification de l'existence de la chaîne de propriétés avec optional chaining
                        const tokens = balanceWallets[wallet]?.tokens[chain]?.tokens;
                        if (tokens) {
                          const tokensList = tokens.filter((object) => object.token.id === token.gnosisContract);
                          tokensList.forEach((object) => {
                            const tokenAmount = parseFloat(object.amount);
                            token.position += tokenAmount;
                            // console.log(holderWallet,chain,token.shortName,tokenAmount)
                          });
                        }
                      }

                      if (chain === 'xdai') {
                        // Vérification de l'existence de la chaîne de propriétés avec optional chaining
                        const tokens = balanceWallets[wallet]?.tokens[chain]?.tokens;
                        if (tokens) {
                          const tokensList = tokens.filter((object) => object.token.id === token.gnosisContract);
                          tokensList.forEach((object) => {
                            const tokenAmount = parseFloat(object.amount);
                            token.position += tokenAmount;
                            // console.log(holderWallet,chain,token.shortName,tokenAmount)
                          });
                        }
                      }

                      if (chain === 'rmm') {
                        // Vérification de l'existence de la chaîne de propriétés avec optional chaining
                        const tokens = balanceWallets[wallet]?.tokens[chain]?.tokens;
                        if (tokens) {
                          const tokensList = tokens.filter((object) => object.token.id === token.gnosisContract);
                          tokensList.forEach((object) => {
                            const tokenAmount = parseFloat(object.amount);
                            token.position += tokenAmount;
                            // console.log(holderWallet,chain,token.shortName,tokenAmount)
                          });
                        }
                      }

                      if (chain === 'rmmv3') {
                        // Vérification de l'existence de la chaîne de propriétés avec optional chaining
                        const tokens = balanceWallets[wallet]?.tokens[chain]?.tokens;
                        if (tokens) {
                          const tokensList = tokens.filter((object) => object.token.id === token.gnosisContract);
                          tokensList.forEach((object) => {
                            const tokenAmount = parseInt(object.amount) / 10 ** object.token.decimals;
                            token.position += tokenAmount;
                            // console.log(holderWallet,chain,token.shortName,tokenAmount)
                          });
                        }
                      }

                      if (chain === 'pool') {
                        const tokens = balanceWallets[wallet]?.tokens[chain]?.tokens;
                        if (tokens) {
                          const tokensList = tokens.filter((object) => 
                            object.pair.token0.id === token.gnosisContract || 
                            object.pair.token1.id === token.gnosisContract
                          );
                          
                          tokensList.forEach((object) => {
                            const liquidityTokenBalance = parseFloat(object.liquidityTokenBalance);
                            const totalSupply = parseFloat(object.pair.totalSupply);
                            
                            if (token.gnosisContract === object.pair.token0.id) {
                              const tokenAmount = object.pair.token0.liquidity > 1000
                                ? (liquidityTokenBalance * object.pair.token0.liquidity) / 10 ** 18 / totalSupply
                                : (liquidityTokenBalance * object.pair.token0.liquidity) / totalSupply;
                              token.position += parseFloat(tokenAmount);
                            }
                            
                            if (token.gnosisContract === object.pair.token1.id) {
                              const tokenAmount = object.pair.token1.liquidity > 1000
                                ? (liquidityTokenBalance * object.pair.token1.liquidity) / 10 ** 18 / totalSupply
                                : (liquidityTokenBalance * object.pair.token1.liquidity) / totalSupply;
                              token.position += parseFloat(tokenAmount);
                            }
                          });
                        }
                      }
                    }
                  }
                }
              });
          });
        }

        // tokens = tokens.map((token) => {
        // 	const startDate = new Date(token.rentStartDate.date);
        // 	return { ...token, startDate: startDate };
        // });

        setTokens(tokens);
      }
    }
  }, [realTokens]);

  useEffect(() => {
    if (tokens) {
      // console.log('tokens', tokens[0]);

      // Étape 1 : Préparer les données historiques
      const tokenData = tokens.map((token) => {
        const startDate = new Date(token.rentStartDate.date); // Convertir la date de départ en Date
        const init_price = token.historic.init_price || [];
        const init_yield = token.historic.init_yield || [];
        const prices = token.historic.prices || []; // variation du prix du token (timsync, price)
        const yields = token.historic.yields || []; // variation du yield (timsync, yield)

        return {
          shortName: token.shortName,
          position: token.position,
          totalTokens: token.totalTokens,
          startDate: startDate,
          init_price: init_price,
          init_yield: init_yield,
          prices: prices.slice(1),
          yields: yields.slice(1),
        };
      });
      // console.table(tokenData.slice(10));

      const generateWeeks = (firstDate, nowDate) => {
        const weeks = [];
        let currentWeek = new Date(firstDate);
        currentWeek.setDate(currentWeek.getDate() - currentWeek.getDay() + 1); // Aller au lundi de la première semaine

        while (currentWeek <= nowDate) {
          const endOfWeek = new Date(currentWeek);
          endOfWeek.setDate(currentWeek.getDate() + 6); // Dimanche de la semaine

          weeks.push({
            startDate: new Date(currentWeek),
            endDate: endOfWeek,
          });

          currentWeek.setDate(currentWeek.getDate() + 7); // Passer à la semaine suivante
        }

        return weeks;
      };

      const firstDate = tokenData.reduce((earliest, token) => (token.startDate < earliest ? token.startDate : earliest), new Date());
      const nowDate = new Date();
      const weeks = generateWeeks(firstDate, nowDate);
      // console.log(tokenData.slice(0, 1));

      const calculateWeeklyRents = (weeks, tokenData) => {
        let weekRentCumul = 0;
        let weekRentEvalCumul = 0;
        return weeks.map((week) => {
          let weekRent = 0;
          let weekRentEval = 0;
          let nbRent = 0;
          let nbRentEval = 0;

          tokenData.forEach((token) => {
            let tokenPrice = token.init_price;
            let tokenPriceOld = token.init_price;
            let tokenYield = token.init_yield;
            let tokenYieldOld = token.init_yield;
            let startDateEval = token.startDate;
            token.prices.forEach((obj) => {
              if (new Date(obj.timsync) < week.startDate) {
                tokenPriceOld = tokenPrice;
                tokenPrice = obj.price;
                startDateEval = new Date(obj.timsync);
              }
            });
            token.yields.forEach((obj) => {
              if (new Date(obj.timsync) < week.startDate) {
                tokenYieldOld = tokenYield;
                tokenYield = obj.yield;
                startDateEval = new Date(obj.timsync);
              }
            });
            // console.log(token.shortName, week.startDate.toISOString().slice(0, 10), week.endDate.toISOString().slice(0, 10));
            // console.log(token.startDate.toISOString().slice(0, 10), startDateEval.toISOString().slice(0, 10));
            // console.log('newPrice',  tokenPrice, 'old', tokenPriceOld);
            // console.log('newYield',  tokenYield, 'old', tokenYieldOld);

            // Vérifier si le token est actif durant cette semaine
            if (token.startDate <= week.startDate) {
              // Utiliser le rendement initial (init_yield) pour calculer le loyer hebdomadaire
              const weeklyRent = ((token.totalTokens * token.init_price * token.init_yield) / 100 / 365) * 7;

              weekRent += weeklyRent;
              nbRent += 1;
            }

            if (startDateEval <= week.startDate && tokenYield > 0) {
              // Utiliser le rendement initial (init_yield) pour calculer le loyer hebdomadaire
              const weeklyRentEval = ((token.totalTokens * tokenPrice * tokenYield) / 100 / 365) * 7;

              weekRentEval += weeklyRentEval;
              nbRentEval += 1;
            }

            if (token.startDate > week.startDate && token.startDate <= week.endDate) {
              // Token actif pour une partie de la semaine
              const nbJours = (week.endDate - token.startDate) / (1000 * 60 * 60 * 24) + 1; // +1 pour inclure le jour de départ
              const partialWeeklyRent = ((token.totalTokens * token.init_price * token.init_yield) / 100 / 365) * nbJours;

              // console.log(week.startDate, partialWeeklyRent);

              weekRent += partialWeeklyRent;
              nbRent += 1;
            }

            if (startDateEval > week.startDate && startDateEval <= week.endDate) {
              // Token actif pour une partie de la semaine
              const nbJours = (week.endDate - startDateEval) / (1000 * 60 * 60 * 24) + 1; // +1 pour inclure le jour de départ
              let partialWeeklyRentEval = 0;
              if (token.startDate < startDateEval) partialWeeklyRentEval += ((token.totalTokens * tokenPriceOld * tokenYieldOld) / 100 / 365) * (7 - nbJours);
              // console.log(week.startDate, partialWeeklyRentEval, nbJours);

              partialWeeklyRentEval += ((token.totalTokens * tokenPrice * tokenYield) / 100 / 365) * nbJours;
              // console.log(week.startDate, partialWeeklyRentEval);

              weekRentEval += partialWeeklyRentEval;
              nbRentEval += 1;
            }
          });

          weekRent = weekRent / 1000;
          weekRentEval = weekRentEval / 1000;
          weekRentCumul += weekRent / 1000;
          weekRentEvalCumul += weekRentEval / 1000;

          return {
            // ...week,
            ronDay: new Date(week.endDate.getTime() + 1000 * 60 * 60 * 36),
            nbRent,
            weekRent: parseFloat(weekRent.toFixed(2)),
            weekRentCumul,
            nbRentEval,
            weekRentEval: parseFloat(weekRentEval.toFixed(2)),
            weekRentEvalCumul,
          };
        });
      };

      const weeklyRents = calculateWeeklyRents(weeks, tokenData);
      // console.table(weeklyRents);

      // Vous pouvez stocker le résultat dans un state ou le passer à un composant
      setListData(weeklyRents);

      // Définir les dates par défaut
      if (weeklyRents.length > 0) {
        setStartDate(weeklyRents[0].ronDay);
        setEndDate(weeklyRents[weeklyRents.length - 1].ronDay);
        // console.log(weeklyRents[0].ronDay);
        // console.log(weeklyRents[weeklyRents.length - 1].ronDay);
      }
    }
  }, [tokens]);

  const width_size_TextField = 150;
  const size_width = 900;
  const size_height = 375;
  const size_width_reinvest = 1000;

  if (!realTokens || !tokens || !listData) {
    return (
      <Paper elevation={3} sx={{ m: 1, minHeight: size_height, width: '60%', border: 1, borderColor: 'primary.main', borderRadius: 4 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: size_height }}>
          <CircularProgress size='4rem' />
          <Typography variant='body2' color='primary.main' sx={{ mt: 2 }}>
            <em>Loading RealT collected Rents...</em>
          </Typography>
        </Box>
      </Paper>
    );
  }

  const filteredData = listData?.filter((item) => new Date(item.ronDay) >= new Date(startDate) && new Date(item.ronDay) <= new Date(endDate));

  return (
    <>
      <Paper elevation={3} sx={{ m: 1, minHeight: size_height, width: '96%', border: 1, borderColor: 'primary.main', borderRadius: 4 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start', m: 0, width: 1 }}>
          <Box sx={{ ml: 1 }}>
            <Typography variant='body2' sx={{ mx: 1, color: 'primary.main', textAlign: 'center' }}>
              Start Date
            </Typography>
            <TextField
              type='date'
              value={startDate ? new Date(startDate).toISOString().slice(0, 10) : ''}
              onChange={(e) => setStartDate(e.target.value)}
              variant='outlined' // Vous pouvez utiliser "standard" ou "filled" pour un autre style
              size='small' // Rend le champ plus compact
              sx={{
                mx: 1, // Ajoute un espace entre le label et le champ
                width: '140px', // Ajuste la largeur
              }}
              InputLabelProps={{
                shrink: true, // Assure que le label reste visible même si le champ est rempli
              }}
              InputProps={{
                sx: {
                  fontSize: 12, // Taille du texte dans le champ
                  // padding: '8px', // Facultatif : ajuste le padding
                },
              }}
            />
          </Box>
          <Box sx={{ ml: 'auto' }}>
            <Typography variant='h5' sx={{ mt: 1, color: 'primary.main', textAlign: 'center' }}>
              RealT Rents Collected
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 0 }}>
              <ButtonGroup color='primary'>
                <Button onClick={() => setVisibleLines(['Rent'])} variant={visibleLines.includes('Rent') && !visibleLines.includes('Initial') ? 'contained' : 'outlined'}>
                  Rent
                </Button>
                <Button
                  onClick={() => setVisibleLines(['Initial'])}
                  variant={!visibleLines.includes('Rent') && visibleLines.includes('Initial') ? 'contained' : 'outlined'}>
                  Initial Rent
                </Button>
                <Button
                  onClick={() => setVisibleLines(['Rent', 'Initial'])}
                  variant={visibleLines.includes('Rent') && visibleLines.includes('Initial') ? 'contained' : 'outlined'}>
                  Both
                </Button>
              </ButtonGroup>
            </Box>
          </Box>
          <Box sx={{ ml: 'auto' }}>
            <Typography variant='body2' sx={{ mx: 1, color: 'primary.main', textAlign: 'center' }}>
              End Date
            </Typography>
            <TextField
              type='date'
              value={endDate ? new Date(endDate).toISOString().slice(0, 10) : ''}
              onChange={(e) => setEndDate(e.target.value)}
              variant='outlined' // Vous pouvez utiliser "standard" ou "filled" pour un autre style
              size='small' // Rend le champ plus compact
              sx={{
                mx: 1, // Ajoute un espace entre le label et le champ
                width: '140px', // Ajuste la largeur
              }}
              InputLabelProps={{
                shrink: true, // Assure que le label reste visible même si le champ est rempli
              }}
              InputProps={{
                sx: {
                  fontSize: 12, // Taille du texte dans le champ
                  // padding: '8px', // Facultatif : ajuste le padding
                },
              }}
            />
          </Box>
        </Box>

        <Box sx={{ p: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', height: size_height }}>
          <ResponsiveContainer width='100%' height={size_height - 50}>
            <LineChart data={filteredData}>
              <CartesianGrid strokeDasharray='3 3' />
              <XAxis
                dataKey='ronDay'
                tickFormatter={(tick) => new Date(tick).toLocaleDateString()}
                tick={{ fontSize: 12 }}
                // label={{ value: 'Week Ending', position: 'insideBottomRight', offset: -5 }}
              />
              {/* Axe gauche pour les cumuls */}
              {/* <YAxis yAxisId='left' label={{ value: 'Cumulative Rent (M$)', angle: -90, position: 'outsideLeft', offset: 10 }} /> */}
              <YAxis yAxisId='left' label={{ value: 'Cumulative Rent (M$)', angle: -90, position: 'outsideLeft', dx: -20 }} />
              {/* Axe droit pour les loyers par semaine */}
              <YAxis yAxisId='right' orientation='right' label={{ value: 'Weekly Rent (k$)', angle: -90, position: 'outsideRight', dx: 20 }} />
              <Tooltip
                formatter={(value, name) => {
                  if (name.includes('Cumulative')) {
                    return [(value / 1).toFixed(2) + ' M$', name];
                  }
                  return [(value / 1).toFixed(2) + ' k$', name];
                }}
              />
              <Legend />
              {/* Ligne pour les cumuls */}
              {visibleLines.includes('Rent') && (
                <>
                  <Line type='monotone' dataKey='weekRentEvalCumul' name='Cumulative Rent' stroke='#ff7300' yAxisId='left' dot={false} />
                  <Line type='monotone' dataKey='weekRentEval' name='Weekly Rent' stroke='#d88484' yAxisId='right' dot={false} />
                </>
              )}
              {/* Lignes pour les loyers par semaine */}
              {visibleLines.includes('Initial') && (
                <>
                  <Line type='monotone' dataKey='weekRentCumul' name='Cumulative Rent Initial' stroke='#8884d8' yAxisId='left' dot={false} />
                  <Line type='monotone' dataKey='weekRent' name='Weekly Rent Initial' stroke='#82ca9d' yAxisId='right' dot={false} />
                </>
              )}
            </LineChart>
          </ResponsiveContainer>
        </Box>
      </Paper>
    </>
  );
}

export default LineChartTokenRent;
